const GIFT_TYPES = {
  ACH: 'A',
  DEBIT: 'D',
};

export const cleanDigits = (str) => str.replace(/[^\d]+/g, ''); // remove all non-digit characters

export const cleanGiftForApi = (gift) => {
  const { PIE, ProtectPANandCVV, } = window; // These are properties provided by JP Morgan needed for card encryption
  const isCardOption = gift.giftOption === 'card';
  const encryptedCC = isCardOption ? ProtectPANandCVV(gift.cardNumber.toString(), gift.cardCVV, true) : ['', '', ''];
  // ProtectPANandCVV provides back array of 3 values, if everything is correct
  // 1. Encrypted CC number
  // 2. Encrypted CVV number
  // 3. Integrity check value

  const giftCleaned = {
    FirstName: gift.FirstName,
    LastName: gift.LastName,
    Phone: cleanDigits(gift.Phone), // make sure phone is just a number for api
    StreetAddress1: gift.StreetAddress1,
    StreetAddress2: gift.StreetAddress2,
    City: gift.City,
    State: gift.State,
    PostalCode: gift.PostalCode,
    EmailAddress: gift.EmailAddress,
    IsAnonymous: gift.IsAnonymous,
    IsSavings: gift.IsSavings || false, // default to false for card option
    Memo: gift.Memo,
    GiftAmounts: gift.GiftAmounts,
    GiftType: isCardOption ? GIFT_TYPES.DEBIT : GIFT_TYPES.ACH,
    // bank account info
    BankAccountNumber: !isCardOption ? gift.BankAccountNumber : '',
    BankRoutingNumber: !isCardOption ? gift.BankRoutingNumber : '',
    // debit card info
    CreditCardName: isCardOption ? gift.cardName : '',
    CreditCardNumber: encryptedCC[0],
    CreditCardCVVCode: encryptedCC[1],
    CreditCardExpirationMoYr: isCardOption ? gift.cardExpiration : '',
    PieIntegrityCheck: encryptedCC[2],
    // PIE values from getPIE return
    PieE: PIE.E,
    PieK: PIE.K,
    PieL: PIE.L,
    PieKeyID: PIE.key_id,
    PiePhaseID: PIE.phase,
    // session id provided from safetech script
    SessionId: gift.sessionId,
  };

  // just simplies GiftAmounts object to what is needed by API
  giftCleaned.GiftAmounts = gift.GiftAmounts.map((giftAmount) => {
    return {
      Code: giftAmount.Code,
      Amount: giftAmount.Amount,
    };
  });

  return giftCleaned;
};

export const htmlEncode = (str) => str.replace(/[&"'<>^\\`:]/g, '');

export const alphaNumericOnly = (str) => str.replace(/[^0-9a-zA-Z]/g, '');