/*
 *
 * PrintOnline Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  currencyFormatter,
  IconBtnTooltip,
  Modal,
  notificationShow,
  loadingAnimationHTML,
  loadingErrorHTML,
} from '@frontend/common';

import logoGifting from '../images/logo_gift.png';

import {
  LABEL_PRINT_FORM,
  GIFT_OPTIONS,
} from '../constants';

import { sendGiftNotice, getPdfGiftNotice, } from '../actions';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

const select = (state) => ({
  gift: state.giftReducer.gift,
  timestamp: state.giftReducer.timestamp,
  cardFees: state.giftReducer.cardFees,
});

export class PrintOnline extends React.Component {
  static propTypes = {
    gift: PropTypes.object.isRequired,
    timestamp: PropTypes.string.isRequired,
    cardFees: PropTypes.array.isRequired,
    notificationShow: PropTypes.func.isRequired,
    sendGiftNotice: PropTypes.func.isRequired,
    getPdfGiftNotice: PropTypes.func.isRequired,
  };

  state = {
    isEmailNoticeModalOpen: false,
    giftIndexToPrint: -1,
    emailAddress: '',
    emailError: '',
    isGiftNoticeLoading: false,
  };

  openPdfGiftNotice = () => {
    const { gift, getPdfGiftNotice, } = this.props;
    const { giftIndexToPrint, } = this.state;

    const statementWindow = window.open('', '_blank'); // needs to be outside of async call to work in Safari
    statementWindow.document.write(loadingAnimationHTML); // this will get replaced by generated pdf once the api loads all data

    const giftInfo = {
      EmailAddress: gift.EmailAddress, // not really used for PDF notice but just sending gifter's email
      Code: gift.GiftAmounts[giftIndexToPrint].Code,
      Amount: gift.GiftAmounts[giftIndexToPrint].Amount,
      IsAnonymous: Boolean(gift.IsAnonymous),
      GifterFirstName: gift.FirstName,
      GifterLastName: gift.LastName,
      Memo: gift.Memo,
      GiftType: gift.giftOption === GIFT_OPTIONS.ONLINE ? 'ACH' : 'Card Contribution',
    };

    this.setState({ isGiftNoticeLoading: true, });
    getPdfGiftNotice(giftInfo)
      .then(({ payload }) => {
        const file = new Blob([payload.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        statementWindow.location = fileURL; // appeases Safari where window.open is outside of async call
      })
      .catch(() => {
        // show error in a new tab by replacing its html content
        statementWindow.document.close(); // clear first
        statementWindow.document.write(loadingErrorHTML);
      })
      .finally(() => {
        this.setState({ isGiftNoticeLoading: false, });
      });
  }

  sendEmailGiftNotice = () => {
    const { gift, sendGiftNotice, notificationShow, } = this.props;
    const { emailAddress, giftIndexToPrint, } = this.state;
    const giftInfo = {
      EmailAddress: emailAddress,
      Code: gift.GiftAmounts[giftIndexToPrint].Code,
      Amount: gift.GiftAmounts[giftIndexToPrint].Amount,
      IsAnonymous: Boolean(gift.IsAnonymous),
      GifterFirstName: gift.FirstName,
      GifterLastName: gift.LastName,
      Memo: gift.Memo,
      GiftType: gift.giftOption === GIFT_OPTIONS.ONLINE ? 'ACH' : 'Card Contribution',
    };

    this.setState({ isGiftNoticeLoading: true, });
    sendGiftNotice(giftInfo)
      .then(() => {
        notificationShow('Gift Notice Sent.', 'success');
        this.setState({ isGiftNoticeLoading: false, isEmailNoticeModalOpen: false, emailAddress: '', emailError: '', });
      })
      .catch(() => this.setState({ isGiftNoticeLoading: false, }));
  }

  renderEmailNoticeModal = () => {
    const { isEmailNoticeModalOpen, emailAddress, isGiftNoticeLoading, emailError, } = this.state;

    return (
      <Modal
        title='Email Gift Notice'
        show={isEmailNoticeModalOpen}
        onCloseModal={() => this.setState({ isEmailNoticeModalOpen: false, emailAddress: '', emailError: '', })}
        modal={false}
        includeLeftCancelBtn
        isLoading={isGiftNoticeLoading}
        actionButtons={[
          {
            label: 'Send',
            buttonType: 'contained',
            action: this.sendEmailGiftNotice,
            disabled: Boolean(emailError) || !emailAddress,
          },
        ]}
      >
        <div>
          <p>Enter the email for the gift notice recipient.</p>
          <TextField
            variant='filled'
            label='Email'
            error={Boolean(emailError)}
            helperText={emailError}
            onChange={e => {
              const emailAddress = e.target.value;
              let emailError = '';
              if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i.test(emailAddress) && emailAddress) { // 'i' for case insensitive
                emailError = 'Please enter valid email address.';
              }
              this.setState({ emailAddress, emailError });
            }}
            value={emailAddress}
            fullWidth
            disabled={isGiftNoticeLoading}
          />
        </div>
      </Modal>
    );
  }

  render() {
    const { gift, timestamp, cardFees, } = this.props;
    const isCardOption = gift.giftOption === GIFT_OPTIONS.CARD;
    const serviceFee = isCardOption ? cardFees.find(fee => fee.GiftAmount === gift.GiftAmounts[0].Amount).GiftFee : '';

    return (
      <div className={styles.container}>
        <h2>Thank you. You have successfully made a contribution.</h2>

        <div className='hideOnPrint'>Please print this page for you records.</div>

        <div className={`${parentStyles.buttons} hideOnPrint`}>
          <div className={parentStyles.button}>
            <Button
              key='print'
              variant='contained'
              onClick={() => window.print()}
            >
              {LABEL_PRINT_FORM}
            </Button>
          </div>
        </div>
        <div className={styles.confirmInfo}>
          <div className={styles.row}>
            <div className={styles.column}>
              <img src={logoGifting} className={styles.logo} alt='gifting logo' />
            </div>
            <div className={styles.column}>
              <div>
                <h3>Your Information</h3>
                <p><strong>{gift.FirstName} {gift.LastName}</strong></p>
                <p>{gift.StreetAddress1}</p>
                {gift.StreetAddress2 && <p>{gift.StreetAddress2}</p>}
                <p>{gift.City}, {gift.State} {gift.PostalCode}</p>
                <p><strong>P</strong>&nbsp;{gift.Phone}</p>
                <p><strong>E</strong>&nbsp;{gift.EmailAddress}</p>
              </div>
            </div>
          </div>

          <div className={styles.isAnonymous}>
            <strong>Is your gift Anonymous</strong>&nbsp;{gift.IsAnonymous ? 'Yes' : 'No'}
          </div>

          <h3>Your Optional Message to the my529 Account Owner</h3>
          <div>{gift.Memo ? gift.Memo : 'No message provided'}</div>

          {isCardOption
            ?
            <div className={styles.giftPaymentInfo}>
              <p><strong>Payment Method:</strong>&nbsp;Debit Card</p>
              <p><strong>Service Fee:</strong>&nbsp;{currencyFormatter(serviceFee)}</p>
            </div>
            :
            <div className={styles.giftPaymentInfo}>
              <h4>Bank Info</h4>
              <p><strong>{gift.BankName}</strong></p>
              <p><strong>Account Type:</strong>&nbsp;{gift.IsSavings ? 'Savings' : 'Checking'}</p>
              <p><strong>Routing Number:</strong>&nbsp;{gift.BankRoutingNumber}</p>
              <p><strong>Account Number:</strong>&nbsp;{gift.BankAccountNumber}</p>
            </div>
          }

          <h3>Gift Information</h3>
          <div className={parentStyles.multipleGifts}>
            <div className={parentStyles.giftAmounts}>
              {gift.GiftAmounts && gift.GiftAmounts.map((giftAmount, giftIndex) => {
                return (
                  <div key={`giftAmount_${giftIndex}`}>
                    <div className={parentStyles.giftAmount}>
                      <div className={`${parentStyles.cell} ${parentStyles.amount}`}>
                        <div className={parentStyles.label}>Amount</div>
                        <div className={parentStyles.data}>{isCardOption ? `$${giftAmount.Amount}` : currencyFormatter(giftAmount.Amount)}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.giftCode}`}>
                        <div className={parentStyles.label}>Code</div>
                        <div className={parentStyles.data}>{giftAmount.Code}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.owner}`}>
                        <div className={parentStyles.label}>Account Owner</div>
                        <div className={parentStyles.data}>{giftAmount.AgentName}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.bene}`}>
                        <div className={parentStyles.label}>Beneficiary</div>
                        <div className={parentStyles.data}>{giftAmount.BeneficiaryName}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.giftNotice}`}>
                        <div className={parentStyles.label} style={{ marginBottom: 0 }}>Gift Notice</div>
                        <div className={parentStyles.data}>
                          <IconBtnTooltip
                            icon='print'
                            onClick={() => this.setState({ giftIndexToPrint: giftIndex }, () => this.openPdfGiftNotice())}
                            title='Print'
                          />
                          <IconBtnTooltip
                            icon='mail'
                            onClick={() => this.setState({ isEmailNoticeModalOpen: true, giftIndexToPrint: giftIndex, })}
                            title='Email'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

          </div>

          <div className={styles.currentTime}>{dayjs(timestamp).format('MM/DD/YYYY h:mm A')} Mountain Time</div>

        </div>


        {this.renderEmailNoticeModal()}
      </div>

    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  sendGiftNotice,
  getPdfGiftNotice,
})(PrintOnline));