/*
*
* GiactRejection Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';

import {
  FormHelperText,
} from '@mui/material';

import {
  Modal,
} from '@frontend/common';

import styles from './styles.module.scss';

const GiactRejectionModalText = {
  btn_close: 'Close',
  head_title: 'Authentication Failed',
  text_not_authenticated: 'Our verification process was not able to authenticate your financial institution account.',
  text_rejection_language_1: 'Please ensure that the name at the financial institution matches your name exactly and that you are not attempting to use a bank account associated with a third party.',
  text_rejection_language_2: 'Account verification services are provided by GIACT Systems, LLC and are based on information provided by financial institutions. my529 does not have information regarding the specific reasons for a decline.',
  text_rejection_language_3: 'How Do I Correct Information GIACT Has About Me?',
  text_rejection_language_4() {
    return (
      <p>If information reflected within your bank or credit union Disclosure of Consumer Report Information is inaccurate, you may initiate a dispute at no cost by calling GIACT Systems toll-free at 833.802.8092 from 8:30 a.m. to 5 p.m. CT, emailing <a href='mailto:support@giact.com' target='_blank' rel='noopener noreferrer'>support@giact.com</a>, or writing to GIACT Systems at:</p>
    );
  },
  text_giact_address_1: 'GIACT Systems, LLC.',
  text_giact_address_2: 'Attention: Consumer Resolutions',
  text_giact_address_3: 'P.O. Box 1116',
  text_giact_address_4: 'Allen, TX 75013',
  text_rejection_language_5: 'For more information, visit ',
  text_rejection_language_6: 'Additional Questions?',
  text_rejection_language_7: 'You may contact my529 by emailing ',
  text_rejection_language_8: ' or by calling our office toll-free at 800.418.2551 on business days from 7 a.m. to 5 p.m. MT.',
};

GiactRejectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseGiactRejectionModal: PropTypes.func.isRequired,
};

export function GiactRejectionModal({ open, handleCloseGiactRejectionModal }) {
  
  return (
    <Modal
      title={GiactRejectionModalText.head_title}
      show={open}
      onCloseModal={() => handleCloseGiactRejectionModal()}
      modal={false}
      maxWidth='md'
      fullWidth={true}
      actionButtons={[
        {
          label: GiactRejectionModalText.btn_close,
          buttonType: 'contained',
          action: () => handleCloseGiactRejectionModal(),
        },
      ]}
    >
      <div className={styles.giactText}>
        <FormHelperText error={true} style={{ fontSize: 'large' }}>
          {GiactRejectionModalText.text_not_authenticated}
        </FormHelperText>
        <ul>
          <li>{GiactRejectionModalText.text_rejection_language_1}</li>
          <li>{GiactRejectionModalText.text_rejection_language_2}</li>
        </ul>
        <h3>{GiactRejectionModalText.text_rejection_language_3}</h3>
        {GiactRejectionModalText.text_rejection_language_4()}
        <ul style={{ listStyleType: 'none' }} >
          <li>{GiactRejectionModalText.text_giact_address_1}</li>
          <li>{GiactRejectionModalText.text_giact_address_2}</li>
          <li>{GiactRejectionModalText.text_giact_address_3}</li>
          <li>{GiactRejectionModalText.text_giact_address_4}</li>
        </ul>
        <p>{GiactRejectionModalText.text_rejection_language_5}<a href='https://www.giact.com/consumer-inquiries/' target='_blank' rel='noopener noreferrer'>https://www.giact.com/consumer-inquiries/</a></p>
        <h3>{GiactRejectionModalText.text_rejection_language_6}</h3>
        <p>{GiactRejectionModalText.text_rejection_language_7}<a href='mailto:info@my529.org' target='_blank' rel='noopener noreferrer'>info@my529.org</a>{GiactRejectionModalText.text_rejection_language_8}</p>
      </div>
    </Modal>
  );
}

export default GiactRejectionModal;