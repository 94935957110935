/*
 *
 * Gifting Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { alphaNumericOnly } from 'utils/helpers/tools';

import {
  TextField,
  Button,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  LoadingOverlay,
} from '@frontend/common';

import GiftLogo from '../GiftLogo';

import giftIcon from '../images/icon_Gift.svg';

import babyShowersBtn from '../images/button_Baby_Showers.png';
import birthdaysBtn from '../images/button_Birthdays.png';
import graduationBtn from '../images/button_Graduation.png';
import holidaysBtn from '../images/button_Holidays.png';
import memorialsBtn from '../images/button_Memorials.png';

import graduation from '../images/graduation.png';
import baby from '../images/baby.png';
import birthday from '../images/birthday.png';
import holiday from '../images/holiday.png';
import memorials from '../images/memorials.png';

import {
  AA_SETUP_ACCOUNT_URL,
  PRINT_GIFT_CERTIFICATE_URL,
  BENE_CHANGE_FORM_URL,
} from '../constants';

import { clearGift, getParameters, getCardFees, } from '../actions';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

const select = (state) => ({
  gift: state.giftReducer.gift,
  parameters: state.giftReducer.parameters,
});

export class Home extends React.Component {
  static propTypes = {
    clearGift: PropTypes.func.isRequired,
    getParameters: PropTypes.func.isRequired,
    getCardFees: PropTypes.func.isRequired,
    parameters: PropTypes.shape({
      Domain: PropTypes.string.isRequired,
      CurrentYear: PropTypes.number.isRequired,
    }),
  };

  state = {
    giftCode: '',
    doner: 'givers',
    altGift: 'babyShowers',
    loadingParameters: false,
  }

  onGiftCodeChange = (e) => {
    this.setState({ giftCode: alphaNumericOnly(e.target.value) });
  }

  onGiftCodeSubmit = (e) => {
    e.preventDefault();
    this.props.history.push(`/${this.state.giftCode}`);
  }

  donerChange = (e, value) => {
    this.setState({ doner: value });
  }

  altGifthange = (e, value) => {
    this.setState({ altGift: value });
  }

  componentDidMount() {
    this.props.clearGift();
    if (!this.props.parameters.CurrentYear) {
      this.setState({ loadingParameters: true });
      Promise.all([
        this.props.getParameters(),
        this.props.getCardFees(),
      ])
        .finally(() => this.setState({ loadingParameters: false }));
    }
  }

  render() {
    const { parameters } = this.props;
    const {
      giftCode,
      doner,
      altGift,
      loadingParameters,
    } = this.state;

    return (
      <div className={styles.container}>
        <GiftLogo />
        <div className={styles.enter}>
          <h1>With College in the Future,<br />Here&apos;s the Perfect Present<sup style={{ fontSize: '40%' }}>&#174;</sup></h1>
          <h3>How does gifting work?</h3>
          <p>
            my529 makes it easy
            for family and friends to contribute funds toward a child&apos;s future qualified higher education expenses. Gifts into a my529 account may be made online, or by mail with a paper check.
          </p>
          <p>
            The process is simple, secure, and requires no registration.
          </p>
          <form className={styles.form}>
            <img src={giftIcon} alt='gift_icon' style={{ height: '90px', filter: 'opacity(0.4)' }} />
            <div className={styles.form_input}>
              <TextField
                variant='filled'
                label='Gift Code'
                onChange={this.onGiftCodeChange}
                value={giftCode}
                fullWidth
              />
            </div>
            <div className={styles.form_button}>
              <LoadingOverlay show={loadingParameters} width='100%'>
                <Button
                  key={'giftCodeSubmit'}
                  disabled={loadingParameters}
                  onClick={this.onGiftCodeSubmit}
                  variant={'contained'}
                  color='primary'
                  type='submit'
                  fullWidth
                  style={{ height: '60px' }}
                >
                  Give a gift
                </Button>
              </LoadingOverlay>
            </div>
          </form>
        </div>

        <Paper square className={styles.giftInfoTabs}>
          <Tabs
            onChange={this.donerChange}
            value={doner}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab
              label={<h3>Give a gift</h3>}
              value={'givers'}
            />
            <Tab
              label={<h3>Receive a gift</h3>}
              value={'accountowners'}
            />
          </Tabs>
          {this.state.doner === 'givers'
            ?
            <div className={styles.giftInfoTabs_tabContent}>
              <p>
                To give a gift, you must use a gift code or link to a personal gift page that you receive from a my529 account owner. This ensures your contribution will be routed to the appropriate my529 account. After you&apos;ve given your gift, either online or by mail, my529 will notify the account owner to let him or her know of your generosity. You may remain anonymous when making an online gift contribution.
              </p>
              <p>
                If you&apos;d like to print a gift certificate to give in person or send by mail, please follow this <a href={PRINT_GIFT_CERTIFICATE_URL} target='_blank' rel='noreferrer noopener'>link</a>.
              </p>
            </div>
            :
            <div className={styles.giftInfoTabs_tabContent}>
              <p>
                To invite family and friends to contribute to your my529 account, please enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a> and select Gifting. When you enroll, you will receive a unique gift code and a link to a personal gift page. When shared with family and friends, the gift code and link ensure the proper deposit of any gift contributions. Your my529 account number remains confidential in the process.
              </p>
              <p>
                For more information about the my529 Gift Program, please visit the <Link to='/faqs'>FAQ pages</Link> on this website.
              </p>
              <p>
                Enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a>, and select Gifting from the sidebar menu.
              </p>
            </div>
          }
        </Paper>

        <div className={styles.altGift_tabs}>
          <h1>
            As an alternative to traditional gifts, consider the my529 Gift Program for:
          </h1>
          <div className={styles.tabsButtons}>
            <Tabs
              onChange={this.altGifthange}
              value={this.state.altGift}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab
                label={(
                  <div className={styles.altGift_imageButton}>
                    <div className={styles.altGift_imageContainer}>
                      <img src={babyShowersBtn} className={styles.altGift_imageButton_img} alt='Baby Showers' />
                    </div>
                    <div className={styles.altGift_tabLabelTxt}>
                      Baby Showers
                    </div>
                  </div>
                )}
                value={'babyShowers'}
              />
              <Tab
                label={(
                  <div className={styles.altGift_imageButton}>
                    <div className={styles.altGift_imageContainer}>
                      <img src={birthdaysBtn} className={styles.altGift_imageButton_img} alt='Brithdays' />
                    </div>
                    <div className={styles.altGift_tabLabelTxt}>
                      Birthdays
                    </div>
                  </div>
                )}
                value={'birthdays'}
              />
              <Tab
                label={(
                  <div className={styles.altGift_imageButton}>
                    <div className={styles.altGift_imageContainer}>
                      <img src={holidaysBtn} className={styles.altGift_imageButton_img} alt='Holidays' />
                    </div>
                    <div className={styles.altGift_tabLabelTxt}>
                      Holidays
                    </div>
                  </div>
                )}
                value={'holidays'}
              />
              <Tab
                label={(
                  <div className={styles.altGift_imageButton}>
                    <div className={styles.altGift_imageContainer}>
                      <img src={graduationBtn} className={styles.altGift_imageButton_img} alt='Graduation' />
                    </div>
                    <div className={styles.altGift_tabLabelTxt}>
                      Graduation
                    </div>
                  </div>
                )}
                value={'graduation'}
              />
              <Tab
                label={(
                  <div className={styles.altGift_imageButton}>
                    <div className={styles.altGift_imageContainer}>
                      <img src={memorialsBtn} className={styles.altGift_imageButton_img} alt='Memorials' />
                    </div>
                    <div className={styles.altGift_tabLabelTxt}>
                      Memorials
                    </div>
                  </div>
                )}
                value={'memorials'}
              />
            </Tabs>
          </div>
          {altGift === 'babyShowers' &&
            <div className={styles.altGift_tabContent}>
              <h1>Shower a baby today for an education tomorrow</h1>
              <img src={baby} alt='baby' />
              <p>
                A baby shower provides the everyday necessities that a newborn requires today. Diapers, onesies, receiving blankets, and bottles.
              </p>
              <p>
                Have you also considered what your child will need tomorrow?
              </p>
              <p>
                As a newborn grows—into a toddler, preschooler, and elementary, junior high, and high school student—a college fund can grow, too. When choosing baby items for your newborn&apos;s gift registry, add my529 to your wish list.
              </p>
              <h2>Set Up an Account for Baby</h2>
              <p>
                An expectant mom (or dad, or any family relation) may <a href={AA_SETUP_ACCOUNT_URL} target='_blank' rel='noreferrer noopener'>set up an account</a> for an unborn child, naming herself as the account beneficiary until the baby is born. When a U.S. Social Security Number is available for the baby, simply change the beneficiary on the account to the baby&apos;s name using a <a href={BENE_CHANGE_FORM_URL} target='_blank' rel='noopener noreferrer'>Beneficiary Change/Correction form</a> (form 510). The new beneficiary must be a &quot;member of the family&quot; of the current beneficiary, as defined in the my529 Program Description.
              </p>
              <p>
                Please note that contributions made to accounts before the baby&apos;s birth, to a beneficiary age 19 or older, do not qualify for the Utah state income tax credit.
              </p>
              <h2>Receive Gifts</h2>
              <p>
                After you&apos;ve opened a new account, enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a> and selecting Gifting. When you enroll, you will receive a unique gift code and a link to a personal gift page. Send the code and link to family and friends and begin investing toward a college education.
              </p>
            </div>
          }
          {altGift === 'birthdays' &&
            <div className={styles.altGift_tabContent}>
              <h1>One more candle, one year closer to college</h1>
              <img src={birthday} alt='birthday cake' />
              <p>
                The years add up quickly.
              </p>

              <p>
                The early years: building blocks, dolls, toys, princess gowns, action figures.
              </p>

              <p>
                As they grow: video games, bicycles, music players, new clothes, cell phones.
              </p>

              <p>
                Eighteen years of birthday gifts accumulate over time. Consider including a few gifts for the future along the way.
              </p>
              <p>
                Enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a> and invite your friends and family to celebrate your child&apos;s graduation with a contribution to his or her my529 account today.
              </p>
            </div>
          }
          {altGift === 'holidays' &&
            <div className={styles.altGift_tabContent}>
              <h1>Sometimes the simplest gifts are the most meaningful</h1>
              <img src={holiday} alt='holiday' />
              <p>
                Tell your friends and family to avoid the traffic, long lines, wandering around toy aisles, or surfing the web, searching for the perfect gift.
              </p>
              <p>
                A college savings account is a gift that children unwrap in the future.
              </p>
              <p>
                Enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a> and invite your friends and family to celebrate your child&apos;s birthday and/or the holidays with a contribution to his or her my529 account today.
              </p>
            </div>
          }
          {altGift === 'graduation' &&
            <div className={styles.altGift_tabContent}>
              <h1>The future has arrived</h1>
              <img src={graduation} alt='graduation' />
              <p>
                High school graduation marks the end of one chapter, and the beginning of another. Celebrate the accomplishment with a gift toward higher education.
              </p>
              <p>
                Enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a> and invite your friends and family to celebrate your child&apos;s graduation with a contribution to his or her my529 account today.
              </p>
            </div>
          }
          {altGift === 'memorials' &&
            <div className={styles.altGift_tabContent}>
              <h1>Make your memorial a gift</h1>
              <img src={memorials} alt='memorials' />
              <p>
                In a time of grief, the my529 Gift Program lets you reach out to your friends, colleagues or your faith community, allowing them to support the process of healing, and create a legacy that honors your loved one.
              </p>
              <p>
                As extended family and members of your social circle seek to support you, consider requesting something in lieu of flowers or a card.
              </p>
              <p>
                Enroll in the my529 Gift Program by logging in to your account at <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a> and share your my529 gift code with your communities. Invite them to help provide a memorial gift of education that can have a lifelong impact.
              </p>
            </div>
          }

        </div>
        <div className={parentStyles.returnToTopLink} onClick={() => window.scroll(0, 0)} >Return to top</div>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  clearGift,
  getParameters,
  getCardFees,
})(Home));