/*
*
* Navigation Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import Header from './Header';
import Sidenav from './Sidenav';
import Home from 'components/Features/public/Home';
import Gifting from 'components/Features/public/Gifting';
import FAQs from 'components/Features/public/FAQs';
import PrintMail from 'components/Features/public/PrintMail';
import ConfirmOnline from 'components/Features/public/ConfirmOnline';
import PrintOnline from 'components/Features/public/PrintOnline';
import PageNotFound from 'components/Features/public/PageNotFound';

import { LegalFooter } from '@frontend/common';

import {
  CONTACT_US_URL,
  MY529_ORG_URL,
  PROGRAM_DESCRIPTION_URL,
} from 'components/Features/public/constants';

import styles from './styles.module.scss';

export class Navigation extends React.Component {

  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  };

  state = {
    showSideNav: false
  };

  onRouteChanged() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged();
    }
  }

  render() {
    const navLinks = [
      {
        name: 'HOME',
        path: '/',
        component: Home,
        icon: 'redeem',
      },
      {
        name: 'FAQ',
        path: '/faqs',
        component: FAQs,
        icon: 'help_outline',
      },
      {
        name: 'CONTACT US',
        path: CONTACT_US_URL,
        icon: 'email'
      },
      {
        name: 'MY529.ORG',
        path: MY529_ORG_URL,
        icon: 'home'
      },
    ];

    return (
      <div className={styles.Navigation_featuresContainer}>
        <div className='hideOnPrint'>
          <Header
            navLinks={navLinks}
            openSideNav={() => this.setState({ showSideNav: true })}
          />
        </div>

        <Sidenav
          navLinks={navLinks}
          onClose={() => this.setState({ showSideNav: false })}
          onOpen={() => this.setState({ showSideNav: true })}
          open={this.state.showSideNav}
        />

        <main className={styles.Navigation_featuresChildren}>
          <Switch>
            <Route
              component={Home}
              path={'/'}
              exact
            />
            <Route
              component={FAQs}
              path={'/faqs'}
              exact
            />
            <Route
              component={PrintMail}
              path={'/print-mail'}
              exact
            />
            <Route
              component={PrintOnline}
              path={'/print-online'}
              exact
            />
            <Route
              component={ConfirmOnline}
              path={'/confirm-online'}
              exact
            />
            <Route
              component={Gifting}
              path={'/:code'}
              exact
            />
            <PageNotFound />
          </Switch>
        </main>
        <div className={`${styles.version} hideOnPrint`}>
          <h5>App version {window.appVersion}</h5>
        </div>
        <LegalFooter programDescriptionLink={PROGRAM_DESCRIPTION_URL} /> {/* TODO: update this link to come from API parameters, at some point */}
      </div>
    );
  }
}

export default withRouter(Navigation);