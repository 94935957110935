/*
 *
 * GiftLogo Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sizify } from '@frontend/common';

import logoGifting from '../images/logo_gift.png';
import logoWhiteGifting from '../images/logo_white_gift.png';

import styles from './styles.module.scss';

export class GiftLogo extends React.Component {

  static propTypes = {
    isGiftingPage: PropTypes.bool,
    size: PropTypes.object.isRequired,
  };

  render() {
    const { isGiftingPage, size, } = this.props;
    return (
      <img src={isGiftingPage && size.windowWidth <= 768 ? logoWhiteGifting : logoGifting} className={styles.img} alt='gifting logo' />
    );
  }
}

export default sizify(GiftLogo);