export const AA_SETUP_ACCOUNT_URL = 'https://login.my529.org/register?_ga=2.100717953.1617089708.1605713030-1999065678.1602701991';
export const PRINT_GIFT_CERTIFICATE_URL = 'https://my529.org/how-to-save/give-a-gift/?_ga=2.103874176.1617089708.1605713030-1999065678.1602701991';
export const CONTACT_US_URL = 'https://my529.org/why-choose-my529/contact-us';
export const MY529_ORG_URL = 'https://my529.org/';
export const BENE_CHANGE_FORM_URL = 'https://my529.org/wp-content/uploads/2022/07/2022.5_510-Beneficiary-Change_FILL-IN.pdf?_ga=2.163182140.1617089708.1605713030-1999065678.1602701991';
export const PROGRAM_DESCRIPTION_URL = 'https://my529.org/wp-content/uploads/docs/my529-Program-Description.pdf';
export const SUPPORT_EMAIL = 'info@my529.org';
export const SUPPORT_PHONE_NUMBER = '800.418.2551';

export const LABEL_CONTINUE = 'CONTINUE';
export const LABEL_REVIEW_GIFT = 'REVIEW GIFT';
export const LABEL_SUBMIT_GIFT = 'SUBMIT GIFT';
export const LABEL_PRINT_FORM = 'PRINT FORM';
export const LABEL_GO_BACK_TO_EDIT = 'GO BACK TO EDIT';
export const LABEL_I_AGREE = 'I AGREE';
export const LABEL_CANCEL = 'CANCEL';

export const GET_USSTATES = 'AppRoot/Features/public/GET_USSTATES';
export const GET_GIFT_INFO = 'AppRoot/Features/public/GET_GIFT_INFO';
export const GET_BANK_NAME = 'AppRoot/Features/public/GET_BANK_NAME';
export const SAVE_GIFT_TO_REDUX = 'AppRoot/Features/public/SAVE_GIFT_TO_REDUX';
export const CLEAR_GIFT = 'AppRoot/Features/public/CLEAR_GIFT';
export const RESET_GIFT_AMOUNTS = 'AppRoot/Features/public/RESET_GIFT_AMOUNTS';
export const POST_GIFT = 'AppRoot/Features/public/POST_GIFT';
export const VALIDATE_GIFT_GIFTMAX = 'AppRoot/Features/public/VALIDATE_GIFT_GIFTMAX';
export const GET_PUBLIC_PARAMETERS = 'AppRoot/Features/public/GET_PUBLIC_PARAMETERS';
export const TOGGLE_GIFT_CODE_PAGE = 'AppRoot/Features/public/TOGGLE_GIFT_CODE_PAGE';
export const GET_CARD_FEES = 'AppRoot/Features/public/GET_CARD_FEES';
export const SEND_GIFT_NOTICE = 'AppRoot/Features/public/SEND_GIFT_NOTICE';
export const GET_PDF_GIFT_NOTICE = 'AppRoot/Features/public/GET_PDF_GIFT_NOTICE';

export const BANK_TYPES = {
  SAVINGS: 'Savings',
  CHECKING: 'Checking',
};

export const GIFT_OPTIONS = {
  MAIL: 'mail',
  ONLINE: 'online',
  CARD: 'card',
};
