/* eslint-disable */
/*
*
* FAQs Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  currencyFormatter,
  LoadingOverlay
} from '@frontend/common';

import {
  Tabs,
  Tab,
} from '@mui/material';

import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE_NUMBER,
} from '../constants';

import { getParameters } from '../actions';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

const select = (state) => ({
  parameters: state.giftReducer.parameters,
});

export class FAQs extends React.Component {
  static propTypes = {
    getParameters: PropTypes.func.isRequired,
    parameters: PropTypes.shape({
      Domain: PropTypes.string.isRequired,
      CurrentYear: PropTypes.number.isRequired,
      AnnualGiftExclusionAmount: PropTypes.number.isRequired,
      MaximumBeneficiaryDeposit: PropTypes.number.isRequired,
      MaximumBenficiaryAccountValueForGift: PropTypes.number.isRequired,
      Hours: PropTypes.string.isRequired,
    }),
  };

  state = {
    doner: 'givers',
    loadingParameters: false,
  }

  donerChange = (e, value) => {
    this.setState({ doner: value });
  }

  renderFAQs = (faqs) => faqs.map(({ q, a }, index) => (
    <div key={index}>
      <h4>{q}</h4>
      <p className={styles.answer}>{a}</p>
    </div>
  ));

  componentDidMount() {
    if (!this.props.parameters.CurrentYear) {
      this.setState({ loadingParameters: true });
      this.props.getParameters()
        .finally(() => this.setState({ loadingParameters: false }));
    }
  }

  render() {
    const { loadingParameters } = this.state;
    const { parameters } = this.props;

    const faqsGivers = [
      {
        q: <span>What is a 529 plan?</span>,
        a: <span>A 529 plan is a tax-advantaged college savings program. Contributions to a 529 account grow tax-deferred from federal income taxes and the earnings are tax-free when used for qualified higher education expenses at most accredited two- and four-year colleges and universities, U.S. vocational-technical schools, and eligible foreign institutions that accept federal financial aid for students.</span>,
      },

      {
        q: <span>What is the my529 Gift Program?</span>,
        a: <span>The my529 Gift Program is a simple and convenient service that allows account owners to invite family and friends to contribute to their my529 college savings account.</span>,
      },

      {
        q: <span>Do I need to be a my529 account owner to give a gift?</span>,
        a: <span>No. Anyone may contribute to a my529 account as long as he or she has the account owner's gift code or the unique link to his or her gift page.</span>,
      },

      {
        q: <span>How do I give a gift to a my529 account owner?</span>,
        a: <span>You must receive a unique gift code and a URL that links to the account owner's personal gift webpage. From the webpage, you may give a gift in three ways: by using a debit card; by making an electronic funds transfer from your bank or credit union; or by mailing a check. When making a debit card contribution, you will provide a debit card number and will be assessed a service fee. When contributing via electronic funds transfer, you will provide a routing number and account number through the account owner's secure gift page. When making a payment by mail, you will print a deposit slip found on the gift page and mail it with a check to my529.</span>,
      },

      {
        q: <span>Instead of participating in the my529 Gift Program, may I open a 529 account for someone?</span>,
        a: <span>Yes. U.S. residents age 18 or older with a U.S. Social Security or Tax Identification Number may open a my529 college savings account for any beneficiary&mdash;family member, friend, or even yourself. The same beneficiary may be named in multiple my529 accounts. Visit <a href={`https://${parameters.Domain}`} target='_blank' rel='noreferrer noopener'>{parameters.Domain}</a> and download the Program Description to learn more and to sign up online.</span>,
      },

      {
        q: <span>How will I know if my529 received my gift?</span>,
        a: <span>my529 will send a confirmation email to you immediately after you've made an electronic contribution. my529 also will notify the account owner by email of the gift. If the payment is in good order, the funds will be posted to the my529 account, usually within three business days.</span>,
      },

      {
        q: <span>How does the account owner know I gave a gift?</span>,
        a: <span>A notification email about the gift will be sent to the my529 account owner when the gift contribution posts to an account. If you make an electronic gift contribution, you may choose to donate funds anonymously.</span>,
      },

      {
        q: <span>Who do I contact if I have a question about the processing of my electronic check transaction?</span>,
        a: <span>You may contact my529 toll-free at {SUPPORT_PHONE_NUMBER}, or by email at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>. Representatives are available to answer questions on regular business days from {parameters.Hours}.</span>,
      },

      {
        q: <span>Are there tax consequences for my gift?</span>,
        a: <span>You may owe a gift tax or generation skipping transfer (GST) tax for gifts of more than {currencyFormatter(parameters.AnnualGiftExclusionAmount)} per year to any one person. Anyone giving large monetary gifts should consult a tax advisor prior to sending the contributions to my529. my529 does not provide tax advice to any person making a gift.</span>,
      },

      {
        q: <span>May I have my gift returned to me?</span>,
        a: <span>No. Gifts contributed to a my529 account are considered “completed” gifts. Once gift funds are contributed to a my529 account, they blend with other contributions. Any withdrawal is treated like a distribution. Returning a gift would be considered a “nonqualified withdrawal,” subject to taxes and a 10 percent federal penalty tax on any earnings. Gifts will be accepted into an account up to a certain limit. When the account balance has reached {currencyFormatter(parameters.MaximumBenficiaryAccountValueForGift)}, any gift contribution will be returned to the person who made it.</span>,
      },

      {
        q: <span>Will the account owner see my bank account information?</span>,
        a: <span>No. Your bank account information will not be shared with the account owner.</span>,
      },

      {
        q: <span>What will my529 do with my bank account information?</span>,
        a: <span>my529 will only use your bank information to process the gift you are giving. my529 places a priority on information security, so transactions are encrypted. If you wish to give another gift at a future date, you will need to re-enter your bank information.</span>,
      },

      {
        q: <span>What if the gift code or the unique link to the account owner's gift page does not work?</span>,
        a: <span>If the gift code or the link does not work, please contact my529 toll-free at {SUPPORT_PHONE_NUMBER} or by email at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>. Representatives are available to answer questions on regular business days from {parameters.Hours}.</span>,
      },

      {
        q: <span>Who should I contact if I have questions pertaining to my529's Gift Program?</span>,
        a: <span>Please contact my529 toll-free at {SUPPORT_PHONE_NUMBER} or by email at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>. Representatives are available to answer questions on regular business days from {parameters.Hours}.</span>,
      },

      {
        q: <span>Does it cost me anything to use the my529 Gift Program?</span>,
        a: <span>There is a service fee charged per debit card transaction by J.P. Morgan as a third-party debit card processor. The fee covers the cost of securely processing debit card contributions. There are no fees associated with online bank account contributions or contributions made by sending a check.</span>,
      },
    ];

    const faqsAccountowners = [
      {
        q: <span>What is the my529 Gift Program?</span>,
        a: <span>The my529 Gift Program is a simple and convenient service that allows account owners to invite family and friends to contribute to their my529 college savings account.</span>,
      },

      {
        q: <span>How do I invite family and friends to participate in my529's Gift Program?</span>,
        a: <span>To give family and friends the opportunity to contribute to your my529 account, please enroll in my529's Gift Program by logging in to your account at {parameters.Domain}. When you enroll, you will receive a unique gift code and a URL that links to your personal gift page. When shared with family and friends, the gift code and link ensure the proper deposit of any gift contributions, without disclosing your personal my529 account information. Anyone with your gift code will be able to access your gift page. As the account owner, you decide with whom you'd like to share the link. You may share this gift code or link by email, in person, by phone, or by mail. my529 also provides links and default messages to share your gift page using Twitter and Facebook. You may copy and use the URL on your other social media sites as well. You may also turn off access to your page by logging in to your account at {parameters.Domain}.</span>,

      },

      {
        q: <span>Do friends and family need to have a my529 account to contribute to my account?</span>,
        a: <span>No. Anyone may contribute to your my529 account as long as he or she has your my529 gift code or link to your gift page.</span>,
      },

      {
        q: <span>What if I don't have someone's email address?</span>,
        a: <span>You may also pass along your gift code in person, by mail, or by phone.</span>,
      },

      {
        q: <span>How will my friends and family give the gift?</span>,
        a: <span>They may give a gift in three ways: by using a debit card; by making an electronic funds transfer from their bank or credit union; or by mailing a check. When making a debit card contribution, they will provide a debit card number and will be assessed a service fee. When contributing via electronic funds transfer, they will provide a routing number and account number through the secure gift page. When making a payment by mail, they will print a deposit slip found on the gift page and mail it with a check to my529.</span>,
      },


      {
        q: <span>When people give gifts, do they have access to my my529 account information?</span>,
        a: <span>No. When someone gives a gift, the gift code or gift link ensures that the funds will be directed to your my529 account without sharing your account number in the process.</span>,
      },

      {
        q: <span>How will I know if I've received a gift?</span>,
        a: <span>my529 will notify you by email when a gift posts to your account. You will be able to view the gift online by logging in to your account, usually within three days after it has posted to your account, and it will be included in your account balance.</span>,
      },

      {
        q: <span>May I specify my unborn baby as a beneficiary on my account to receive gifts before the baby is born?</span>,
        a: <span>Because a U.S. Social Security Number is required for all beneficiaries, your unborn baby cannot yet be named as the beneficiary on the account. You may, however, name yourself as the beneficiary until the baby is born. When your baby is born, simply change the beneficiary on your account to your baby's name using a Beneficiary Change/Correction form (form 510). The new beneficiary must be a "member of the family" of the current beneficiary, as defined in the my529 Program Description. Please note that contributions made to accounts before the baby's birth, to a beneficiary age 19 or older, do not qualify for the Utah state income tax credit.</span>,
      },

      {
        q: <span>May I return a gift made to my my529 account?</span>,
        a: <span>No. Gifts made to a my529 account are considered "completed" gifts. Once gift funds are invested in your my529 account, they blend with other contributions. Any withdrawal is treated as a distribution from your account. Returning a gift would be considered a “nonqualified withdrawal,” subject to taxes and a 10 percent federal penalty tax on any earnings. To return a gift, you may want to withdraw money from a different type of account, your checking or savings account, for example, to avoid taxes.</span>,
      },

      {
        q: <span>What if I have a question about a gift made to my account?</span>,
        a: <span>You may contact my529 toll-free at {SUPPORT_PHONE_NUMBER} or by email at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>. Representatives are available to answer questions on regular business days from {parameters.Hours}.</span>,
      },

      {
        q: <span>Do I owe taxes on gifts made to my 529 account?</span>,
        a: <span>You should not owe taxes on gifts made to your my529 account, but people who make large gifts to your account may owe gift taxes. A gift tax or generation skipping transfer (GST) tax may occur for someone making gifts more than {currencyFormatter(parameters.AnnualGiftExclusionAmount)} per year to any one person. Anyone giving large monetary gifts should consult a tax advisor before sending the contributions to my529. my529 does not provide tax advice to the person giving the gift.</span>,
      },

      {
        q: <span>Does the gift count toward my my529 account balance limit?</span>,
        a: <span>Yes. For {parameters.CurrentYear}, my529 will accept contributions until all my529 account balances for the same beneficiary total {currencyFormatter(parameters.MaximumBeneficiaryDeposit)}, above which additional contributions are not allowed. However, there is a separate limit for gifts. When the account balance has reached {currencyFormatter(parameters.MaximumBenficiaryAccountValueForGift)}, any gift contribution will be returned to the person who made it. Please see the my529 Program Description, downloadable at <a href={`https://${parameters.Domain}`} target='_blank' rel='noreferrer noopener'>{parameters.Domain}</a>, for more information about contribution limits.</span>,
      },

      {
        q: <span>When are gift contributions invested?</span>,
        a: <span>If the contribution is received in good order, it will be posted to your my529 account within three business days.</span>,
      },

      {
        q: <span>How are gift contributions allocated?</span>,
        a: <span>Gift contributions will be allocated according to the investment option and/or allocations that you, as the account owner, have selected for your my529 account.</span>,
      },

      {
        q: <span>Are there any fees associated with giving a gift through the my529 Gift Program?</span>,
        a: <span>There is a service fee charged per debit card transaction by J.P. Morgan as a third-party debit card processor. The fee covers the cost of securely processing debit card contributions. There are no fees associated with online bank account contributions or contributions made by sending a check.</span>,
      },

      {
        q: <span>Will the unique gift code and URL that links to my gift page ever expire?</span>,
        a: <span>No. Both the unique gift code and URL will be active as long as you participate in the my529 Gift Program.</span>,
      },

      {
        q: <span>Are gift contributions available for immediate withdrawal?</span>,
        a: <span> If your account ownership with my529 is less than six months old, gift contributions made online using a bank account or using a check by mail are held for 20 business days. If your account ownership has been established for at least six months, online gift contributions are held for at least seven business days. Gift Program contributions made by debit card cannot be withdrawn until 45 business days after deposit.</span>,
      },
    ];

    return (
      <div className={styles.container}>
        <h2>Frequently Asked Questions</h2>
        {loadingParameters ? <LoadingOverlay show={true} width='100%' /> :
          <div className={styles.giftInfoTabs}>
            <Tabs
              onChange={this.donerChange}
              value={this.state.doner}
              indicatorColor='primary'
              textColor='primary'
              centered
            >
              <Tab
                label={<h3>For Givers</h3>}
                value={'givers'}
              />
              <Tab
                label={<h3>For Account Owners</h3>}
                value={'accountowners'}
              />
            </Tabs>
            <div className={styles.qAndA}>
              {this.state.doner === 'givers'
                ?
                <div>
                  {this.renderFAQs(faqsGivers)}
                </div>
                :
                <div>
                  {this.renderFAQs(faqsAccountowners)}
                </div>
              }
            </div>
          </div>
        }
        <div className={parentStyles.returnToTopLink} onClick={() => window.scroll(0, 0)} >Return to top</div>
      </div>
    );
  }
}


export default connect(select, {
  getParameters,
})(FAQs);