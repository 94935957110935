/*
*
* Header Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sizify } from '@frontend/common';
import {
  AppBar,
  Icon,
  IconButton,
  Toolbar
} from '@mui/material';

import styles from './styles.module.scss';

const select = (state) => ({
  onGiftCodePage: state.giftReducer.onGiftCodePage,
});

export class Header extends React.Component {

  static propTypes = {
    navLinks: PropTypes.array.isRequired,
    openSideNav: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
    onGiftCodePage: PropTypes.bool.isRequired,
  };


  render() {
    const { onGiftCodePage, } = this.props;
    return (
      <AppBar 
        style={{ zIndex: 'none !important' }}
        position={onGiftCodePage ? 'sticky' : 'fixed'}
      >

        {this.props.size.windowWidth > 1200 ?
          <Toolbar className={`${styles.headerContainerLarge} ${onGiftCodePage ? styles.headerOnGiftPage : ''}`}>
            <nav className={styles.headerNavLinks}>
              {this.props.navLinks.map(link => link && (
                link.path.indexOf('https') !== -1 ?
                  <a
                    className={styles.navLink}
                    href={encodeURI(link.path)}
                    key={link.path}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {link.name}
                  </a>
                  :
                  <NavLink
                    activeStyle={{
                      fontWeight: 'bold',
                      textDecoration: 'underline solid var(--alternateText)'
                    }}
                    className={styles.navLink}
                    exact
                    key={link.path}
                    to={link.path}
                  >
                    {link.name}
                  </NavLink>
              ))}
            </nav>
          </Toolbar>
          :
          <Toolbar className={`${styles.headerContainerSmall} ${onGiftCodePage ? styles.headerOnGiftPage : ''}`}>
            <IconButton onClick={() => this.props.openSideNav()} aria-label='Main menu'>
              <Icon className={styles.menuIcon}>menu</Icon>
            </IconButton>
          </Toolbar>
        }
      </AppBar>
    );
  }
}

export default withRouter(sizify(connect(select, {})(Header)));