import { combineReducers } from '@reduxjs/toolkit';

import Notifications from '@frontend/common/build/components/Notifications/reducer';
import GiftReducer from 'components/Features/public/reducer';

const rootReducer = combineReducers({
  notifications: Notifications,
  giftReducer: GiftReducer,
});

export default rootReducer;