/*
*
* Sidenav Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

const themeStyles = theme => ({
  listItemText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '24px',
    color: theme.palette.primary.main,
    lineHeight: 'initial',
  },
  icon: {
    color: theme.palette.primary.main,
  }
});

export class Sidenav extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    navLinks: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  render() {
    const { classes, history, onClose, onOpen, open, navLinks } = this.props;
    return (
      <SwipeableDrawer
        open={open}
        onClose={onClose}
        onOpen={onOpen}
      >
        <List>
          {navLinks.map(link => link && (
            <ListItem
              button
              key={link.path}
              onClick={() => { link.path.indexOf('https') !== -1 ? window.open(link.path, '_blank') : history.push(link.path); onClose(); }} // eslint-disable-line
            >
              <ListItemIcon><Icon className={classes.icon} color='secondary'>{link.icon}</Icon></ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary={link.name} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    );
  }
}

export default withRouter(withStyles(themeStyles)(Sidenav));
