/*
 *
 * PrintMail Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { cleanGiftForApi } from 'utils/helpers/tools';

import GiactRejectionModal from './GiactRejectionModal';

import {
  Button,
} from '@mui/material';

import {
  notificationShow,
  allNotificationsHide,
  LoadingOverlay,
  currencyFormatter,
} from '@frontend/common';

import {
  postGift,
} from '../actions';

import {
  LABEL_GO_BACK_TO_EDIT,
  LABEL_I_AGREE,
  LABEL_CANCEL,
  SUPPORT_PHONE_NUMBER,
} from '../constants';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

const select = (state) => ({
  gift: state.giftReducer.gift,
});

export class ConfirmOnline extends React.Component {
  static propTypes = {
    gift: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    postGift: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    showGiactRejectionModal: false,
  };

  onConfirmationSubmit = (e) => {
    const { gift } = this.props;
    e.preventDefault();
    this.setState({ loading: true });

    const giftCleaned = cleanGiftForApi(gift);

    this.props.postGift(giftCleaned)
      .then(() => {
        this.setState({ loading: false }, () => {
          this.props.history.push('/print-online');
          this.props.notificationShow('You successfully added gifts.', 'success');
        });
      })
      .catch((response) => {
        // check if GIACT error
        const data = response.payload.data;
        const hasGiactErrors = data && Array.isArray(data) && data.find((error) => error.Field === 'GiactFailed');
        if (hasGiactErrors)
          this.setState({ loading: false, showGiactRejectionModal: true });
        else
          this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.props.allNotificationsHide();
  }

  render() {
    const { gift } = this.props;
    const { loading } = this.state;
    const backToEditURL = gift.GiftAmounts.length > 0 ? `/${gift.GiftAmounts[0].Code}` : '/';

    return (
      <div className={styles.container}>
        <div className={styles.confirmInfo}>

          <h2>Gift Review</h2>

          <div className={styles.helpInfo}>
            <ul>
              <li>
                Review the information below.
              </li>
              <li>
                If the information is correct, click the <i>{LABEL_I_AGREE}</i> button below.
              </li>
              <li>
                If you need to make any changes:
                <ol>
                  <li>
                    Click the <i>{LABEL_GO_BACK_TO_EDIT}</i> button to edit any information.
                  </li>
                  <li>
                    Complete the gift by clicking the <i>{LABEL_I_AGREE}</i> button below.
                  </li>
                </ol>
              </li>
            </ul>
          </div>

          <div className={styles.yourInfo}>
            <h3>Your Information</h3>
            <p><b>{gift.FirstName} {gift.LastName}</b></p>
            <p>{gift.StreetAddress1}</p>
            {gift.StreetAddress2 && <p>{gift.StreetAddress2}</p>}
            <p>{gift.City}, {gift.State} {gift.PostalCode}</p>
            <p><b>P</b> {gift.Phone}</p>
            <p><b>E</b> {gift.EmailAddress}</p>
          </div>

          <div className={styles.isAnonymous}>
            <b>Is your gift Anonymous</b>&nbsp;{gift.IsAnonymous ? 'Yes' : 'No'}
          </div>

          <h3>Your Optional Message to the my529 Account Owner</h3>
          <div>{gift.Memo ? gift.Memo : 'No message provided'}</div>

          <div className={styles.paymentInfo}>
            <h3>Bank Info</h3>
            <p><b>{gift.BankName}</b></p>
            <p>Account Type: {gift.IsSavings ? 'Savings' : 'Checking'}</p>
            <p>Routing Number: {gift.BankRoutingNumber}</p>
            <p>Account Number: {gift.BankAccountNumber}</p>
          </div>

          <h3>Gift Information</h3>
          <div className={parentStyles.multipleGifts}>
            <div className={parentStyles.giftAmounts}>
              {gift.GiftAmounts && gift.GiftAmounts.map((giftAmount, index) => {
                return (
                  <div key={`giftAmount_${index}`}>
                    <div className={parentStyles.giftAmount}>
                      <div className={`${parentStyles.cell} ${parentStyles.amount}`}>
                        <div className={parentStyles.label}>Amount</div>
                        <div className={parentStyles.data}>{currencyFormatter(giftAmount.Amount)}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.giftCode}`}>
                        <div className={parentStyles.label}>Code</div>
                        <div className={parentStyles.data}>{giftAmount.Code}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.owner}`}>
                        <div className={parentStyles.label}>Account Owner</div>
                        <div className={parentStyles.data}>{giftAmount.AgentName}</div>
                      </div>
                      <div className={`${parentStyles.cell} ${parentStyles.bene}`}>
                        <div className={parentStyles.label}>Beneficiary</div>
                        <div className={parentStyles.data}>{giftAmount.BeneficiaryName}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <p>
              I authorize my529 to initiate, and my financial institution to honor, electronic payments from my account. This authority pertains to the bank account listed above.  The information I have provided is accurate.  I understand that rejected transactions or insufficient funds could cause fees to be assessed by my529 and/or my financial institution.
            </p>
            <p>
              Cancellation Instructions: This authorization is to remain in full effect until I notify my529 of the revocation of this authorization by contacting my529 by telephone at {SUPPORT_PHONE_NUMBER} I understand that any revocation will not be effective unless my529 has had a reasonable opportunity to act on the termination notification
            </p>
          </div>

        </div>
        <div className={`${parentStyles.buttons} hideOnPrint`}>
          <div className={parentStyles.button}>
            <Button
              key='print'
              variant='outlined'
              onClick={() => this.props.history.push('/')}
              color='primary'
              disabled={loading}
            >
              {LABEL_CANCEL}
            </Button>
          </div>
          <div className={parentStyles.button}>
            <Button
              key='print'
              variant='outlined'
              onClick={() => this.props.history.push(backToEditURL)}
              color='primary'
              disabled={loading}
            >
              {LABEL_GO_BACK_TO_EDIT}
            </Button>
          </div>
          <div className={parentStyles.button}>
            <LoadingOverlay show={loading} width='100%'>
              <Button
                key='print'
                variant='contained'
                onClick={this.onConfirmationSubmit}
              >
                {LABEL_I_AGREE}
              </Button>
            </LoadingOverlay>
          </div>
        </div>

        <GiactRejectionModal
          open={this.state.showGiactRejectionModal}
          handleCloseGiactRejectionModal={() => this.setState({ showGiactRejectionModal: false })}
        />
      </div>

    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  allNotificationsHide,
  postGift,
})(ConfirmOnline));