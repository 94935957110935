/*
*
* PageNotFound Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Icon,
  Button,
} from '@mui/material';
import { pageNotFoundHit } from 'utils/ga/events.js';
import styles from './styles.module.scss';

export function PageNotFound({ history }) {
  pageNotFoundHit(window.location.pathname);
  return (
    <div className={styles.container}>
      <div className={styles.shrug}>
        {'¯\\_(ツ)_/¯'}
      </div>
      <p className={styles.message}>
      {"Oops! Looks like you reached a page that doesn't exist."} {/* eslint-disable-line */}
      </p>
      <Button
        variant='contained'
        onClick={() => history.push('/')}
      >
        <Icon>keyboard_arrow_left</Icon>
        Back to Home
      </Button>
    </div>
  );
}

PageNotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PageNotFound);