import * as globalVars from './_sassconfig.module.scss';
import { lighten } from '@mui/material/styles';

const appColors = {
  primary: globalVars.primary || '#000', // Appeases the unit test gods' inability to load css
  accent: globalVars.accent || '#FFF', // Appeases the unit test gods' inability to load css
  accent2: globalVars.accent2 || '#FFF', // Appeases the unit test gods' inability to load css
  error: globalVars.error,
  text: globalVars.text,
  lightHeaderText: globalVars.lightHeaderText,
  linkText: globalVars.linkText,
  darkPrimary: globalVars.darkPrimary,
  lightGray: globalVars.lightGray,
};

export default {
  palette: {
    common: {
      warn: 'rgb(250, 229, 0)'
    },
    primary: {
      main: appColors.primary,
      light: lighten(appColors.primary, 0.65),
      dark: appColors.primary,
    },
    secondary: {
      main: appColors.accent,
      light: lighten(appColors.accent, 0.65),
    },
    accent2: {
      main: appColors.accent2,
      light: lighten(appColors.accent2, 0.85),
    },
    error: {
      main: appColors.error
    },
    text: {
      main: appColors.text
    },
    lightHeaderText: {
      main: appColors.lightHeaderText
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: '10',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 35, // makes buttons rounded
        }, 
        contained: {
          color: '#fff',
          backgroundColor: appColors.primary,
          '&:hover': {
            backgroundColor: lighten(appColors.primary, 0.4),
          },
        },
        outlined: {
          borderColor: appColors.primary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: appColors.accent,
        },
        deleteIcon: {
          color: '#fff',
          '&:hover': {
            color: 'rgb(215, 215, 215)',
          },
        },
        avatar: {
          backgroundColor: '#fff',
          color: appColors.accent,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: appColors.accent,
          '&:hover': {
            backgroundColor: lighten(appColors.accent, 0.4),
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: 'none'
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: 'none'
          },
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, .89)',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '5px 0',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: appColors.primary,
          },
          '&:before': {
            borderBottom: '1px solid rgb(175, 175, 175)'
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid rgb(175, 175, 175)',
          },
        },
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$active': {
            color: appColors.accent,
          },
          '&$completed': {
            color: appColors.accent,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&$selected': {
            color: appColors.accent,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'PT Sans',
      'Arial',
      'sans-serif',
    ]
  },
  breakpoints: {
    values: { // all following values should be non-zero except in tests
      xs: 0,
      sm: globalVars.mobileWidth ? parseInt(globalVars.mobileWidth) : 0,
      md: globalVars.tabletWidth ? parseInt(globalVars.tabletWidth) : 0,
      lg: globalVars.desktopWidth ? parseInt(globalVars.desktopWidth) : 0,
    },
  }
};
