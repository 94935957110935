/*
*
* Gifting Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { cleanDigits, cleanGiftForApi, htmlEncode, alphaNumericOnly } from 'utils/helpers/tools';

import {
  Avatar,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  InputLabel,
  MenuItem,
  Select,
  Paper,
} from '@mui/material';

import {
  Add,
  Person,
} from '@mui/icons-material';

import {
  notificationShow,
  allNotificationsHide,
  LoadingOverlay,
  InfoIcon,
  CurrencyInput,
  IconBtnTooltip,
  Dropdown,
  BaseNumberInput,
  currencyFormatter,
  Checkbox,
} from '@frontend/common';

import GiftLogo from '../GiftLogo';
import visaLogo from './Logos/visa_blue.png';
import mastercardLogo from './Logos/mastercard.png';
import discoverLogo from './Logos/discover.png';

import {
  saveGiftToRedux,
  getGiftInfoByCode,
  getUSStates,
  getBankNameByRouting,
  validateGiftAndGetGiftMax,
  toggleOnGiftCodePage,
  resetGiftAmounts,
  postGift,
  getParameters,
  getCardFees,
} from '../actions';

import {
  LABEL_CONTINUE,
  LABEL_REVIEW_GIFT,
  LABEL_SUBMIT_GIFT,
  BANK_TYPES,
  GIFT_OPTIONS,
} from '../constants';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

import checkExample from '../images/checkExample.gif';
import giftIcon from '../images/icon_Gift.svg';

const select = (state) => ({
  gift: state.giftReducer.gift,
  USStates: state.giftReducer.USStates,
  cardFees: state.giftReducer.cardFees,
  creditCardContributionsEnabled: state.giftReducer.parameters.CreditCardContributionsEnabled,
  CreditCardOrbitalEncryptionGetKeyJS: state.giftReducer.parameters.CreditCardOrbitalEncryptionGetKeyJS,
  onGiftCodePage: state.giftReducer.onGiftCodePage,
});

export class Gifting extends React.Component {
  static propTypes = {
    validateGiftAndGetGiftMax: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    saveGiftToRedux: PropTypes.func.isRequired,
    getGiftInfoByCode: PropTypes.func.isRequired,
    getBankNameByRouting: PropTypes.func.isRequired,
    getUSStates: PropTypes.func.isRequired,
    USStates: PropTypes.array.isRequired,
    gift: PropTypes.object.isRequired,
    cardFees: PropTypes.array.isRequired,
    toggleOnGiftCodePage: PropTypes.func.isRequired,
    onGiftCodePage: PropTypes.bool.isRequired,
    resetGiftAmounts: PropTypes.func.isRequired,
    postGift: PropTypes.func.isRequired,
    getParameters: PropTypes.func.isRequired,
    getCardFees: PropTypes.func.isRequired,
    creditCardContributionsEnabled: PropTypes.bool.isRequired,
    CreditCardOrbitalEncryptionGetKeyJS: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    loadingBankName: false,
    submitCardLoading: false,
    hideComponent: true,
    newGiftCode: '',
    giftImages: [],
    isKaptchaLoaded: false,
  }

  validateCode = (code) => {
    // sanitize code first
    code = alphaNumericOnly(code);
    this.setState({ loading: true });

    Promise.all([
      this.props.getGiftInfoByCode(code),
      this.props.gift.GiftAmounts.length === 0 && this.props.getParameters() // make sure latest parameters are loaded on first gift code
        .then(() => !window.PIE && this.getEncryptionKey()), // if the encryption PIE object doesn't exist, run that script
      this.props.cardFees.length === 0 && this.props.getCardFees(), // make sure card fees are loaded
    ])
      .then(([response]) => {
        this.setState({
          loading: false,
          hideComponent: true
        });
        const giftInfo = response.payload.data;
        const { gift } = this.props;
        const { giftImages } = this.state;
        if (giftInfo) {
          const alreadyExists = gift.GiftAmounts.find(giftInfo => giftInfo.Code === code);
          if (alreadyExists) {
            this.props.notificationShow(`The gift code ${code} already exists`, 'error');
            return false;
          }
          else {
            const convertedImage = this.convertImage(giftInfo.Image);

            // store main gift info into Redux
            const updatedGiftInfo = { ...giftInfo, Image: null };
            gift.GiftAmounts.push(updatedGiftInfo);
            gift.currentGiftIndex = gift.GiftAmounts.length - 1;
            this.props.saveGiftToRedux(gift);

            // store gift image into state to avoid session storage limit going over
            const newGiftImage = { Code: giftInfo.Code, Image: convertedImage };
            giftImages.push(newGiftImage);
            // also clear the input field
            this.setState({ giftImages, newGiftCode: '' });
            this.props.allNotificationsHide();
          }
        }
        else {
          if (gift.GiftAmounts.length === 0) {
            this.props.history.push('/');
          }
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          hideComponent: true
        });
        if (this.props.gift.GiftAmounts.length === 0) {
          this.props.history.push('/');
        }
      });
  }

  validateAmounts = () => {
    const isCorrect = !this.props.gift.GiftAmounts.find(giftAmount => !giftAmount.Amount || giftAmount.Amount < 1);
    if (!isCorrect)
      this.props.notificationShow('Gifts must be at least $1.00.', 'error');
    return isCorrect;
  }


  validateForm = () => {
    const {
      gift: {
        FirstName,
        LastName,
        EmailAddress,
        confirmEmailAddress,
        StreetAddress1,
        City,
        State,
        PostalCode,
        Phone,
        IsSavings,
        BankAccountNumber,
        confirmBankAccountNumber,
        BankRoutingNumber,
        cardNumber,
        cardName,
        cardExpiration,
        cardCVV,
        giftOption,
      },
    } = this.props;

    let isCorrect = true;
    const isCardOption = giftOption === GIFT_OPTIONS.CARD;

    if (!this.validateAmounts()) {
      isCorrect = false;
    }

    if (!FirstName || !LastName) {
      this.props.notificationShow('Please enter your first and last names.', 'error');
      isCorrect = false;
    }

    if (!StreetAddress1) {
      this.props.notificationShow('Please enter address line one.', 'error');
      isCorrect = false;
    }

    if (!City) {
      this.props.notificationShow('Please enter city.', 'error');
      isCorrect = false;
    }

    if (!State) {
      this.props.notificationShow('Please select state.', 'error');
      isCorrect = false;
    }

    if (!PostalCode) {
      this.props.notificationShow('Please enter postal code.', 'error');
      isCorrect = false;
    }
    else if (cleanDigits(PostalCode).length !== 5 && cleanDigits(PostalCode).length !== 9) {
      this.props.notificationShow('Please enter valid zip+4 postal code.', 'error');
      isCorrect = false;
    }

    if (!EmailAddress) {
      this.props.notificationShow('Please enter your email address.', 'error');
      isCorrect = false;
    }
    else if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i.test(EmailAddress)) { // 'i' for case insensitive
      this.props.notificationShow('Please enter valid email address.', 'error');
      isCorrect = false;
    }
    else if (confirmEmailAddress.toLowerCase() !== EmailAddress.toLowerCase()) {
      this.props.notificationShow('Your email addresses don\'t match.', 'error');
      isCorrect = false;
    }

    if (cleanDigits(Phone).length !== 10) {
      this.props.notificationShow('Please enter 10-digit phone number.', 'error');
      isCorrect = false;
    }

    if (isCardOption) { // card option validation
      if (!cardName) {
        this.props.notificationShow('Please enter the name on card.', 'error');
        isCorrect = false;
      }

      if (!cardNumber || cardNumber.toString().length !== 16) {
        this.props.notificationShow('Please enter 16-digit card number.', 'error');
        isCorrect = false;
      }

      if (!cardExpiration || cardExpiration.length !== 5) {
        this.props.notificationShow('Please enter valid card MM/YY expiration date.', 'error');
        isCorrect = false;
      }

      if (!cardCVV || cardCVV.length !== 3) {
        this.props.notificationShow('Please enter valid CVV number.', 'error');
        isCorrect = false;
      }
    }
    else { // bank account option validation
      if (IsSavings === '') {
        this.props.notificationShow('Please select your bank account type.', 'error');
        isCorrect = false;
      }

      if (!BankAccountNumber) {
        this.props.notificationShow('Please enter your bank account number.', 'error');
        isCorrect = false;
      }
      else if (confirmBankAccountNumber !== BankAccountNumber) {
        this.props.notificationShow('Your bank account numbers don\'t match.', 'error');
        isCorrect = false;
      }

      if (!BankRoutingNumber) {
        this.props.notificationShow('Please enter your bank account routing/transit number.', 'error');
        isCorrect = false;
      }
      else if (cleanDigits(BankRoutingNumber).length !== 9) {
        this.props.notificationShow('Please enter 9-digit bank account routing/transit number.', 'error');
        isCorrect = false;
      }
    }

    return isCorrect;
  }

  formatPhone = (phone) => {
    // Strip all characters from the input except digits
    phone = phone.replace(/\D/g, '');
    // Trim the remaining phone to ten characters, to preserve phone number format
    phone = phone.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    const size = phone.length;
    if (size === 0) {
      phone = '';
    }
    else if (size < 4) {
      phone = `(${phone}`;
    }
    else if (size < 7) {
      phone = `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}`;
    }
    else {
      phone = `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
    }

    return phone;
  };

  formatZip = (zip) => {
    const zipCleaned = cleanDigits(zip);
    if (zip.length <= 5) {
      zip = zipCleaned;
    }
    else if (zip.length === 6 && zip.charAt(5) === '-') { // let user type the dash      
      zip = `${zipCleaned.substring(0, 5)}-`;
    }
    else {
      zip = `${zipCleaned.substring(0, 5)}-${zipCleaned.substring(5, 9)}`;
    }

    return zip;
  }

  formatRoutingNumber = (num) => {
    if (num.length <= 9)
      return num;
    else
      return num.substring(0, 9);
  }

  onGiftChangeHandler = (value, inputName) => {
    const updatedGift = this.props.gift;
    updatedGift[inputName] = value;
    this.props.saveGiftToRedux(updatedGift);
  }

  onMailInSubmit = (e) => {
    e.preventDefault();
    this.props.allNotificationsHide();
    if (this.validateAmounts())
      this.props.history.push('/print-mail');
  }

  onOnlineReview = (e) => {
    e.preventDefault();
    const { gift } = this.props;
    this.props.allNotificationsHide();

    if (this.validateForm()) {
      this.setState({ loading: true });
      const giftCleaned = cleanGiftForApi(gift);

      this.props.validateGiftAndGetGiftMax(giftCleaned)
        .then(() => {
          // gift max values should get set through redux now
          this.setState({ loading: false });
          this.props.history.push('/confirm-online');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  onCardSubmit = (e) => {
    e.preventDefault();
    const { gift } = this.props;
    this.props.allNotificationsHide();

    if (this.validateForm()) { // replace validation method
      this.setState({ submitCardLoading: true });
      gift.sessionId = window.ka.sessionId; // this comes from safetech script in public index.html file
      const giftCleaned = cleanGiftForApi(gift);

      this.props.postGift(giftCleaned)
        .then(() => {
          this.setState({ submitCardLoading: false }, () => {
            this.props.history.push('/print-online'); // might need to make new print card page or just alter the print-online page
            this.props.notificationShow('You have successfully completed a gift.', 'success');
          });
        })
        .catch((response) => {
          // reset session id for kaptcha, if card submit comes back with any error
          this.getSessionId();
          this.setState({ submitCardLoading: false, isKaptchaLoaded: false, });

          // check if debit card error
          const data = response.payload.data;
          // check for decline or invalid card format
          const hasCardError = data && Array.isArray(data) && data.find((error) => error.Field === 'Decline' || error.Field === 'Invalid');
          if (hasCardError) {
            // clear out debit card fields
            const updatedGift = this.props.gift;
            updatedGift.cardNumber = '';
            updatedGift.cardName = '';
            updatedGift.cardExpiration = '';
            updatedGift.cardCVV = '';
            this.props.saveGiftToRedux(updatedGift);
          }
          this.setState({ submitCardLoading: false });
        });
    }
  }

  onRowClick = (index) => {
    this.props.gift.currentGiftIndex = index;
    this.props.saveGiftToRedux(this.props.gift);
  }

  onRowDelete = (index) => {
    const { gift } = this.props;
    const { giftImages } = this.state;
    gift.GiftAmounts.splice(index, 1);

    // remove image from state giftImages, if exists
    const giftImageIndex = giftImages.findIndex(image => image.Code === gift.GiftAmounts);
    if (giftImageIndex > -1) giftImages.splice(index, 1);

    // make sure there is at least one gift code
    if (gift.GiftAmounts.length === 0)
      this.props.history.push('/');

    // change the current gift index also
    gift.currentGiftIndex = index > 1 ? index - 1 : 0;

    this.props.saveGiftToRedux(gift);
    this.setState({ giftImages });
  }

  onAmountChange = ({ floatValue = 0 }, index) => {
    if (!isNaN(floatValue)) {
      const updatedGift = this.props.gift;
      updatedGift.GiftAmounts[index].Amount = floatValue;
      this.props.saveGiftToRedux(updatedGift);
    }
  }

  onGiftCodeChange = (e) => {
    const newGiftCode = alphaNumericOnly(e.target.value);
    this.setState({ newGiftCode });
    this.onGiftChangeHandler(newGiftCode, 'addedCode');
  }

  addCode = (e) => {
    e.preventDefault();
    const { gift: { addedCode, GiftAmounts } } = this.props;
    if (GiftAmounts && GiftAmounts.length > 29) // check for max of 30 gifts
      this.props.notificationShow('You can only add up to 29 additional gift codes.', 'error');
    else {
      this.setState({ hideComponent: false });
      this.validateCode(addedCode);
    }
  }

  onBankTypeChange = (e) => {
    if (e.target.value) {
      this.onGiftChangeHandler(e.target.value === BANK_TYPES.SAVINGS, 'IsSavings');
    }
  }

  getRoutingNumberBankName = () => {
    this.setState({ loadingBankName: true });
    this.props.getBankNameByRouting(this.props.gift.BankRoutingNumber)
      .then((response) => {
        this.setState({ loadingBankName: false });
        this.props.gift.BankName = response.payload.data.BankName;
        this.props.saveGiftToRedux(this.props.gift);
      })
      .catch(() => {
        this.setState({ loadingBankName: false });
      });
  }

  convertByteArrayToImage(data) {
    const arrayBuffer = new Uint8Array(data);
    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  }

  convertB64ToImage(data) {
    if (data.includes('JFIF')) {
      return `data:image/jpeg;base64,${data}`;
    }
    else if (data.includes('GIF89a')) {
      return `data:image/gif;base64,${data}`;
    }
    else {
      return `data:image/png;base64,${data}`;
    }
  }

  convertImage = (Image) => {
    let constructedImage = '';
    if (Image) {
      if (Array.isArray(Image)) {
        constructedImage = this.convertByteArrayToImage(Image);
      }
      else {
        constructedImage = this.convertB64ToImage(Image);
      }
    }

    return constructedImage;
  }

  renderImage = (convertedImage) => {
    if (convertedImage) {
      return (<Avatar style={{ width: '100%', height: '100%' }} src={convertedImage} alt='Beneficiary' />);
    }
    else {
      return (<Avatar style={{ width: '100%', height: '100%' }}><Person className={styles.personAvatar} /></Avatar>);
    }
  }

  renderGifterInputs = () => {
    const {
      USStates,
      gift: {
        IsAnonymous,
        Phone,
        State,
        PostalCode,
        FirstName,
        LastName,
        StreetAddress1,
        StreetAddress2,
        City,
        EmailAddress,
        confirmEmailAddress,
        Memo,
      },
    } = this.props;
    const { submitCardLoading, } = this.state;

    return (
      <>
        <h3>Your name</h3>
        <div className={styles.formInputs}>
          <div className={`${styles.formInput} ${styles.marginRight}`}>
            <TextField
              variant='filled'
              autoComplete='on'
              required
              label={'Enter your first name'}
              onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'FirstName')}
              value={FirstName}
              fullWidth
              disabled={submitCardLoading}
            />
          </div>
          <div className={styles.formInput}>
            <TextField
              variant='filled'
              autoComplete='on'
              required
              label={'Enter your last name'}
              onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'LastName')}
              value={LastName}
              fullWidth
              disabled={submitCardLoading}
            />
          </div>
        </div>

        <Checkbox
          checked={IsAnonymous}
          onChange={(e) => this.onGiftChangeHandler(e.target.checked, 'IsAnonymous')}
          color='primary'
          disabled={submitCardLoading}
          label={
            <span style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
              Give your gift anonymously
              <InfoIcon message={<span>Your information will not be shared with the account owner. <br />my529 still requires your information in order to process your gift.</span>} />
            </span>
          }
        />

        <div>
          <h3>Address</h3>
          <TextField
            variant='filled'
            required
            autoComplete='on'
            label={'Address Line One'}
            onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'StreetAddress1')}
            value={StreetAddress1}
            fullWidth
            disabled={submitCardLoading}
          />
          <TextField
            variant='filled'
            autoComplete='on'
            label={'Address Line Two (optional)'}
            onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'StreetAddress2')}
            value={StreetAddress2}
            fullWidth
            disabled={submitCardLoading}
          />
          <TextField
            variant='filled'
            autoComplete='on'
            required
            label={'City'}
            onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'City')}
            value={City}
            fullWidth
            disabled={submitCardLoading}
          />
          <div className={styles.stateZip}>
            <div className={styles.state}>
              <FormControl
                variant='filled'
                required
                style={{ width: '100%' }}
              >
                <InputLabel>State</InputLabel>
                <Select
                  value={State}
                  onChange={(e) => this.onGiftChangeHandler(e.target.value, 'State')}
                >
                  {USStates.map((state) => <MenuItem key={state.Code} value={state.Code}>{state.Name}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div className={styles.zip}>
              <TextField
                variant='filled'
                autoComplete='on'
                required
                label={'Zip+4'}
                onChange={(e) => this.onGiftChangeHandler(this.formatZip(e.target.value), 'PostalCode')}
                value={PostalCode}
                fullWidth
                disabled={submitCardLoading}
              />
            </div>
          </div>
        </div>

        <h3>Email</h3>
        <TextField
          variant='filled'
          autoComplete='on'
          required
          label={'Email'}
          onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'EmailAddress')}
          value={EmailAddress}
          fullWidth
          disabled={submitCardLoading}
        />
        <TextField
          variant='filled'
          autoComplete='off'
          required
          label={'Confirm Email'}
          onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'confirmEmailAddress')}
          onPaste={(e) => e.preventDefault()} // prevent pasting, user has to type it
          value={confirmEmailAddress}
          fullWidth
          disabled={submitCardLoading}
        />

        <h3>Phone</h3>
        <TextField
          variant='filled'
          autoComplete='on'
          required
          label={'Phone'}
          onChange={(e) => this.onGiftChangeHandler(this.formatPhone(e.target.value), 'Phone')}
          value={Phone}
          fullWidth
          disabled={submitCardLoading}
        />

        <h3>Optional Message</h3>
        <div>
          <TextField
            variant='outlined'
            label={'Optional Message from you to the my529 Account Owner'}
            onChange={(e) => this.onGiftChangeHandler(decodeURI(e.target.value).replace(/[&"<>^\\`:]/g, ''), 'Memo')} // remove dangerous tags except apostrophe
            value={Memo}
            multiline
            minRows={4}
            fullWidth
            disabled={submitCardLoading}
          />
        </div>
      </>
    );
  }

  getEncryptionKey = () => {
    const { CreditCardOrbitalEncryptionGetKeyJS, } = this.props;

    // delete script if it already exists
    const scriptElem = document.getElementById('pie');
    if (scriptElem) scriptElem.remove();

    if (CreditCardOrbitalEncryptionGetKeyJS) {
      // get the PIE object required for card encryption, required to have a new key for each card transaction
      const script = document.createElement('script');
      script.src = CreditCardOrbitalEncryptionGetKeyJS;
      script.async = true;
      script.id = 'pie';
      document.body.appendChild(script);
    }
  }

  getSessionId = () => {
    window.ka = {}; // reset global ka object
    let HOST_URL = 'ssl.kaptcha.com'; // production safetech url
    if (window.location.host.includes('localhost') || window.location.host.includes('dev') || window.location.host.includes('qa')) {
      HOST_URL = 'tst.kaptcha.com'; // test safetech url
    }
    const merchantId = '300795';
    const src = `https://${HOST_URL}/collect/sdk?m=${merchantId}`;

    // delete script if it already exists
    const scriptElem = document.getElementById('kaptcha');
    if (scriptElem) scriptElem.remove();

    // get the ka object with the sessionId required for safetech kaptcha
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.id = 'kaptcha';
    document.body.appendChild(script);
  }

  componentDidMount() {
    const { gift, match: { params: { code } }, toggleOnGiftCodePage, onGiftCodePage, } = this.props;

    !onGiftCodePage && toggleOnGiftCodePage();

    this.getSessionId();
    this.getEncryptionKey();

    if (gift.GiftAmounts.length === 0)
      this.validateCode(code);
    else { // make sure to always get the latest parameters, mainly would be in case of a page reload
      this.setState({ loading: true, });
      this.props.getParameters() // mainly wanting the latest parameters to make sure CreditCardContributionsEnabled is current
        .then(() => this.setState({ loading: false, }));
    }

    if (this.props.USStates.length === 0)
      this.props.getUSStates();
  }

  componentDidUpdate() {
    const { gift } = this.props;
    const { isKaptchaLoaded } = this.state;

    if (!isKaptchaLoaded && !isEmpty(window.ka) && gift.giftOption === GIFT_OPTIONS.CARD) {

      // trigger safetech kaptcha, if debit card option is selected
      const client = new window.ka.ClientSDK();
      client.autoLoadEvents();
      this.setState({ isKaptchaLoaded: true, });
    }
  }

  componentWillUnmount() {
    this.props.toggleOnGiftCodePage();
  }

  render() {
    const {
      gift: {
        GiftAmounts,
        IsSavings,
        BankRoutingNumber,
        BankAccountNumber,
        confirmBankAccountNumber,
        giftOption,
        currentGiftIndex,
        FirstName,
        LastName,
        BankName,
        cardName,
        cardNumber,
        cardExpiration,
        cardCVV,
      },
      resetGiftAmounts,
      cardFees,
      creditCardContributionsEnabled,
    } = this.props;

    const {
      loading,
      loadingBankName,
      submitCardLoading,
      hideComponent,
      newGiftCode,
      giftImages,
    } = this.state;

    const canMultiGifts = giftOption === GIFT_OPTIONS.ONLINE || giftOption === GIFT_OPTIONS.MAIL;
    const currentGiftAmount = GiftAmounts.length > 0 ? GiftAmounts[currentGiftIndex] : { imgSrc: null };
    const currentGiftImage = giftImages.length > 0 ? giftImages.find(image => image.Code === GiftAmounts[currentGiftIndex].Code) || { imgSrc: null } : { imgSrc: null };
    let bankType = IsSavings; // this can be an empty string, true or false
    if (IsSavings !== '') {
      bankType = IsSavings ? BANK_TYPES.SAVINGS : BANK_TYPES.CHECKING;
    }

    return (
      <div className={styles.container}>

        {loading && hideComponent
          ?
          <div className={styles.loadingBarContainer}>
            <LoadingOverlay show={true} width='100%' />
          </div>
          :
          <>
            <div className={styles.main}>
              <div className={styles.receiverPhoto}>
                {this.renderImage(currentGiftImage.Image)}
              </div>

              <div className={styles.names}>
                <div className={styles.nameLabel}>
                  BENEFICIARY
                </div>
                <div className={styles.beneName}>
                  {currentGiftAmount.BeneficiaryName}
                </div>
                <div className={styles.nameLabel}>
                  my529 ACCOUNT OWNER
                </div>
                <div className={styles.agentName}>
                  {currentGiftAmount.AgentName}
                </div>
              </div>

              <div className={styles.giftLogo}>
                <GiftLogo isGiftingPage />
              </div>
            </div>

            {currentGiftAmount.Message &&
              <Paper square className={styles.message}>
                {currentGiftAmount.Message}
              </Paper>}

            <div className={styles.divider} />

            <div className={styles.optionForm}>
              <h1>Send Gift Method</h1>
              <div className={styles.giftOption}>
                <RadioGroup
                  value={giftOption}
                  onChange={(e) => {
                    this.onGiftChangeHandler(e.target.value, 'giftOption');
                    resetGiftAmounts();
                  }}
                >
                  {creditCardContributionsEnabled &&
                    <FormControlLabel
                      label={'Debit Card - Make a contribution using your card.'}
                      value={GIFT_OPTIONS.CARD}
                      control={<Radio disableRipple color='primary' />}
                    />}
                  <FormControlLabel
                    label={'Online - Make an electronic funds transfer from my bank.'}
                    value={GIFT_OPTIONS.ONLINE}
                    control={<Radio disableRipple color='primary' />}
                  />
                  <FormControlLabel
                    label={'Mail - Make a contribution with a check by mail.'}
                    value={GIFT_OPTIONS.MAIL}
                    control={<Radio disableRipple color='primary' />}
                  />
                </RadioGroup>
              </div>
            </div>

            {giftOption &&
              <div className={parentStyles.multipleGifts}>
                <h1 className={styles.giftListHeader}>Send a Gift</h1>
                <div className={parentStyles.giftAmounts}>
                  <List
                    aria-label='your gifts'
                    style={{ width: '100%' }}
                  >
                    {GiftAmounts.map((giftAmount, index) => {
                      return (
                        <div key={`giftAmount_${index}`}>
                          <ListItem
                            button
                            divider
                            onClick={() => this.onRowClick(index)}
                            selected={index === currentGiftIndex}
                            className={styles.giftListItem}
                          >
                            <div className={styles.giftAmountContainer}>
                              <div className={styles.giftIcon}>
                                <img src={giftIcon} alt='gift_icon' style={{ height: '90px', filter: 'opacity(0.4)' }} />
                              </div>
                              <div className={styles.amountInput}>
                                {giftOption === GIFT_OPTIONS.CARD
                                  ?
                                  <Dropdown
                                    label='Gift Amount'
                                    value={giftAmount.Amount || ''}
                                    required
                                    onChange={(value) => this.onAmountChange({ floatValue: value }, index)}
                                    options={cardFees.map(amount => ({
                                      value: amount.GiftAmount,
                                      display: `$${amount.GiftAmount} + ${currencyFormatter(amount.GiftFee)} Fee`,
                                    }))}
                                  />
                                  :
                                  <CurrencyInput
                                    label='Gift Amount'
                                    required
                                    onChange={(value) => this.onAmountChange(value, index)}
                                    value={giftAmount.Amount || ''}
                                    variant='filled'
                                  />}
                              </div>
                              <div className={styles.giftCode}>
                                <div className={styles.label}>Gift Code</div>
                                <div className={styles.data}>{giftAmount.Code}</div>
                              </div>
                              <div className={styles.giftAgent}>
                                <div className={styles.label}>my529 Account Owner</div>
                                <div className={styles.data}>{giftAmount.AgentName}</div>
                              </div>
                              <div className={styles.giftBene}>
                                <div className={styles.label}>Account Beneficiary</div>
                                <div className={styles.data}>{giftAmount.BeneficiaryName}</div>
                              </div>
                            </div>
                            {canMultiGifts &&
                              <ListItemSecondaryAction>
                                <IconBtnTooltip
                                  icon='delete'
                                  onClick={() => this.onRowDelete(index)}
                                  title='Delete'
                                />
                              </ListItemSecondaryAction>}
                          </ListItem>
                        </div>
                      );
                    })}
                  </List>
                </div>
              </div>}

            {canMultiGifts &&
              <>
                <h3>Giving more than one gift?</h3>
                <p>Save time by entering your information only once to give multiple gifts.</p>
                <div className={styles.addCode}>
                  <div className={styles.addCodeInput}>
                    <TextField
                      variant='filled'
                      autoComplete='off'
                      label='Gift Code'
                      onChange={this.onGiftCodeChange}
                      value={newGiftCode}
                      fullWidth
                      disabled={loading}
                    />
                  </div>
                  <div className={styles.addCodeButton}>
                    <LoadingOverlay show={loading} width='100%'>
                      <Button
                        key='addCode'
                        color='primary'
                        variant='outlined'
                        onClick={this.addCode}
                        startIcon={<Add />}
                        style={{ height: '60px', width: '200px' }}
                      >
                        Add Gift Code
                      </Button>
                    </LoadingOverlay>
                  </div>
                </div>
              </>}
          </>
        }

        {giftOption === GIFT_OPTIONS.MAIL && (
          <div className={styles.mailInForm}>

            <form onSubmit={this.onMailInSubmit}>
              <h3>Your name (optional)</h3>
              <div className={styles.formInputs}>
                <div className={`${styles.formInput} ${styles.marginRight}`}>
                  <TextField
                    variant='filled'
                    autoComplete='on'
                    label={'Enter your first name'}
                    onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'FirstName')}
                    value={FirstName}
                    fullWidth
                  />
                </div>
                <div className={styles.formInput}>
                  <TextField
                    variant='filled'
                    autoComplete='on'
                    label={'Enter your last name'}
                    onChange={(e) => this.onGiftChangeHandler(htmlEncode(e.target.value), 'LastName')}
                    value={LastName}
                    fullWidth
                  />
                </div>
              </div>

              <div className={styles.giverSubmit}>
                <Button
                  key='giverSubmitMail'
                  variant='contained'
                  type='submit'
                  autoFocus
                >
                  {LABEL_CONTINUE}
                </Button>
              </div>
            </form>
          </div>
        )}

        {giftOption === GIFT_OPTIONS.ONLINE && (
          <div className={styles.onlineForm}>
            <h3>
              Complete the form below to make an electronic funds transfer from your bank account.
            </h3>
            <div>Form inputs with a star (*) are required.</div>

            <form onSubmit={this.onOnlineReview}>
              {this.renderGifterInputs()}

              <div className={styles.accountNotes}>
                The following types of accounts cannot be used to fund gift contributions. Requests to do so will be rejected and may incur reject fees and/or market losses:
                <ul>
                  <li>
                    Brokerage and mutual fund accounts (such as Vanguard, Charles Schwab, etc.)
                  </li>
                  <li>
                    Accounts drawn on credit unions that do not clear their own checks (they use third-party routing numbers)
                  </li>
                  <li>
                    Savings accounts with restricted access privileges
                  </li>
                  <li>
                    Bank accounts with UGMA/UTMA designations
                  </li>
                </ul>
                Please Note:
                <ul>
                  <li>
                    If multiple contributions are made from the same bank account on the same day, the contributions may be consolidated into one debit that equals the total contributions from your account.
                  </li>
                  <li>
                    The funds will normally be debited from your bank account within three business days.
                  </li>
                  <li>
                    You will receive an email from my529 confirming your contribution.
                  </li>
                  <li>
                    Please ensure that funds are available. Any returned transactions or insufficient funds could cause fees to be assessed by my529 and/or your financial institution.
                  </li>
                </ul>
              </div>

              <div>
                <h3>Bank Account</h3>
                <div>
                  <RadioGroup
                    required
                    onChange={this.onBankTypeChange} /* value is always casted to a string */
                    value={bankType}
                    row
                    style={{ justifyContent: 'center' }}
                  >
                    <FormControlLabel
                      label={'Checking'}
                      value={BANK_TYPES.CHECKING}
                      control={<Radio disableRipple color='primary' />}
                    />
                    <FormControlLabel
                      label={'Savings'}
                      value={BANK_TYPES.SAVINGS}
                      control={<Radio disableRipple color='primary' />}
                    />
                    <div className={styles.required}>*</div>
                  </RadioGroup>
                </div>

                <div className={styles.bankInfoSection}>
                  How to find the bank routing and account numbers?
                  <div>
                    <InfoIcon
                      message={<img src={checkExample} className={styles.checkImage} alt='Check Example' />}
                    />
                  </div>
                </div>

                <LoadingOverlay show={loadingBankName} width='100%'>
                  <TextField
                    variant='filled'
                    autoComplete='on'
                    required
                    label={'Routing/Transit Number'}
                    onChange={(e) => {
                      const value = this.formatRoutingNumber(cleanDigits(e.target.value));
                      this.onGiftChangeHandler(value, 'BankRoutingNumber');

                      if (value.length === 9) this.getRoutingNumberBankName(); // if full routing number, lookup bank name
                      else this.onGiftChangeHandler('', 'BankName'); // otherwise, make it empty
                    }}
                    value={BankRoutingNumber}
                    fullWidth
                  />
                </LoadingOverlay>

                {BankName && <div><b>{BankName}</b></div>}
                <TextField
                  variant='filled'
                  autoComplete='on'
                  required
                  label={'Account Number'}
                  onChange={(e) => this.onGiftChangeHandler(cleanDigits(e.target.value), 'BankAccountNumber')}
                  value={BankAccountNumber}
                  fullWidth
                />
                <TextField
                  variant='filled'
                  autoComplete='off'
                  required
                  label={'Confirm Account Number'}
                  onChange={(e) => this.onGiftChangeHandler(cleanDigits(e.target.value), 'confirmBankAccountNumber')}
                  onPaste={(e) => e.preventDefault()} // prevent pasting, user has to type it
                  value={confirmBankAccountNumber}
                  fullWidth
                />

                <div className={styles.accountNotes}>
                  <ul>
                    <li>
                      The account must be a checking or savings account at a bank or credit union located in the United States.
                    </li>
                    <li>
                      You must own the account and your name must be in the account title.
                    </li>
                    <li>
                      my529 will verify the information you provide with the appropriate financial institution.
                    </li>
                    <li>
                      Please note that my529 does not accept every bank or financial institution. If you receive a &quot;Not Accepted&quot; message, please try an account from a different institution.
                    </li>
                  </ul>
                </div>

              </div>

              <div className={styles.giverSubmit}>
                <Button
                  key='giverSubmitOnline'
                  variant='contained'
                  type='submit'
                >
                  {LABEL_REVIEW_GIFT}
                </Button>
              </div>
            </form>
          </div>
        )}

        {giftOption === GIFT_OPTIONS.CARD && (
          // the special class and data-event in the main div is for safetech kaptcha to be triggered
          <div className={`${styles.onlineForm} kaxsdc`} data-event='load'>
            <p>
              This payment will be assessed a service fee by J.P. Morgan who is facilitating card processing on this gifting platform and paying costs associated with processing this transaction. The service fee amount, which will appear as a separate transaction charged to your debit card, will display on the confirmation screen and any statement you receive from your financial institution.
            </p>
            <h3>
              Complete the form below to make an electronic funds transfer from your debit card.
            </h3>
            <div>Form inputs with a star (*) are required.</div>

            <form onSubmit={this.onCardSubmit}>
              {this.renderGifterInputs()}

              <div className={styles.cardLogos}>
                <img src={visaLogo} alt='visa' className={styles.cardLogo} />
                <img src={mastercardLogo} alt='mastercard' className={styles.cardLogo} />
                <img src={discoverLogo} alt='discover' className={styles.cardLogo} />
              </div>
              <div className={styles.cardFormInputs}>
                <BaseNumberInput
                  variant='filled'
                  autoComplete='on'
                  required
                  label='Debit Card Number'
                  onChange={(value) => this.onGiftChangeHandler(value.floatValue, 'cardNumber')}
                  value={cardNumber}
                  fullWidth
                  disabled={submitCardLoading}
                  isNumericFormat={false}
                  inputProps={{
                    format: '#### #### #### ####', // this also causes maxLength of 16
                    mask: '_',
                  }}
                />
                <TextField
                  variant='filled'
                  autoComplete='on'
                  required
                  label='Name on Card'
                  onChange={(e) => this.onGiftChangeHandler(e.target.value, 'cardName')}
                  value={cardName}
                  fullWidth
                  disabled={submitCardLoading}
                />
                <BaseNumberInput
                  variant='filled'
                  autoComplete='off'
                  required
                  label='Expiration'
                  onChange={(value) => this.onGiftChangeHandler(value.formattedValue, 'cardExpiration')}
                  value={cardExpiration}
                  fullWidth
                  disabled={submitCardLoading}
                  placeholder='MM/YY'
                  isNumericFormat={false}
                  inputProps={{
                    format: '##/##',
                  }}
                />
                <TextField
                  variant='filled'
                  autoComplete='off'
                  required
                  label='CVV'
                  onChange={(e) => this.onGiftChangeHandler(cleanDigits(e.target.value), 'cardCVV')}
                  value={cardCVV}
                  fullWidth
                  disabled={submitCardLoading}
                  inputProps={{ maxLength: '3', }}
                />
              </div>

              <div className={styles.accountNotes}>
                <ul>
                  <li>
                    Gift Program contributions should be made using <b>debit cards</b> only. This includes Visa, Mastercard, and Discover debit cards. Credit cards should NOT be used for contributions. If a credit card is used, the transactions may be viewed as a cash advance by the credit card issuer and will likely incur additional fees.
                  </li>
                  <li>
                    Contributions made by a debit card cannot be withdrawn or transferred for at least 45 business days after deposit.
                  </li>
                  <li>
                    Please note that a third-party service fee will be charged for each gift transaction made via debit card. The service fee amounts vary by the selected preset dollar amount and are displayed next to the preset dollar amount selected. There will be two separate transactions that appear on your statement. One transaction will appear as your contribution to my529. The service fee will appear as a separate transaction listed as “J.P. Morgan Service Fee”.
                  </li>
                  <li>
                    Neither the contribution nor the service fee is refundable.
                  </li>
                  <li>
                    my529 will send an email confirmation to the debit card contributor as well as the account owner acknowledging receipt of the debit card transaction. Your statement will reflect all debit card transactions.
                  </li>
                  <li>
                    If your debit card contribution is declined, please contact your bank or card issuer for more details.
                  </li>
                  <li>
                    my529 does not capture or store any debit card information. Learn more at gift.my529.org.
                  </li>
                  <li>
                    my529 representatives may not be able to answer specific questions about declined transactions.
                  </li>
                  <li>
                    If your debit card is declined, please consider making a gift using another method.
                  </li>
                </ul>
              </div>

              <div className={styles.giverSubmit}>
                <LoadingOverlay show={submitCardLoading}>
                  <Button
                    key='giverSubmitCard'
                    variant='contained'
                    type='submit'
                    disabled={submitCardLoading}
                  >
                    {LABEL_SUBMIT_GIFT}
                  </Button>
                </LoadingOverlay>
              </div>
            </form>
          </div>
        )}

      </div>
    );
  }
}

export default withRouter(
  connect(select, {
    validateGiftAndGetGiftMax,
    notificationShow,
    allNotificationsHide,
    saveGiftToRedux,
    getGiftInfoByCode,
    getBankNameByRouting,
    getUSStates,
    toggleOnGiftCodePage,
    resetGiftAmounts,
    postGift,
    getParameters,
    getCardFees,
  })(Gifting));