import {
  SAVE_GIFT_TO_REDUX,
  CLEAR_GIFT,
  RESET_GIFT_AMOUNTS,
  GET_USSTATES,
  POST_GIFT,
  VALIDATE_GIFT_GIFTMAX,
  GET_PUBLIC_PARAMETERS,
  TOGGLE_GIFT_CODE_PAGE,
  GET_CARD_FEES,
} from './constants';

import { cloneDeep } from 'lodash';

const initialState = {
  gift: {
    FirstName: '',
    LastName: '',
    StreetAddress1: '',
    StreetAddress2: '',
    City: '',
    State: '',
    PostalCode: '',
    BankAccountNumber: '',
    confirmBankAccountNumber: '',
    BankRoutingNumber: '',
    BankName: '',
    GiftAmounts: [],
    EmailAddress: '',
    IsSavings: '',
    Memo: '',
    IsAnonymous: false,
    Phone: '',
    confirmEmailAddress: '',
    addedCode: '',
    giftOption: '',
    currentGiftIndex: 0,
    cardNumber: '',
    cardName: '',
    cardExpiration: '',
    cardCVV: '',
  },
  USStates: [],
  timestamp: '',
  parameters: {
    Domain: '',
    AnnualGiftExclusionAmount: 0,
    MaximumBeneficiaryDeposit: 0,
    MaximumBenficiaryAccountValueForGift: 0,
    Hours: '',
    CurrentYear: 0,
    CreditCardContributionsEnabled: false,
    CreditCardOrbitalEncryptionGetKeyJS: '',
  },
  onGiftCodePage: false,
  cardFees: [],
};

function GiftReducer(state = initialState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_USSTATES: {
      newState.USStates = action.payload.data;   
      return newState;
    }

    case SAVE_GIFT_TO_REDUX: {
      newState.gift = action.payload;  
      return newState;
    }

    case CLEAR_GIFT: {
      newState.gift = cloneDeep(initialState.gift);
      newState.timestamp = '';
      return newState;
    }

    case RESET_GIFT_AMOUNTS: {
      // reset to original gift code with amounts cleared
      newState.gift.currentGiftIndex = 0;
      newState.gift.GiftAmounts = [newState.gift.GiftAmounts[0]];
      newState.gift.GiftAmounts[0].Amount = 0;
      newState.gift.GiftAmounts[0].BankAccountGiftBalance = 0;
      return newState;
    }

    case POST_GIFT: {
      newState.timestamp = action.payload.data;
      return newState;
    }

    case VALIDATE_GIFT_GIFTMAX: {
      const { GiftAmounts } = action.payload.data;  
      // update only max gif values
      newState.gift.GiftAmounts = newState.gift.GiftAmounts.map((giftAmount) => {
        // find gift max by code first
        const payloadGiftAmount = GiftAmounts.find((payloadGiftAmount) => payloadGiftAmount.Code === giftAmount.Code);
        // update gift max only
        giftAmount.BankAccountGiftBalance = payloadGiftAmount.BankAccountGiftBalance;
        return giftAmount;
      });
      return newState;
    }

    case GET_PUBLIC_PARAMETERS: {
      newState.parameters = action.payload.data;
      return newState;
    }

    case TOGGLE_GIFT_CODE_PAGE: {
      newState.onGiftCodePage = !newState.onGiftCodePage;
      return newState;
    }

    case GET_CARD_FEES: {
      newState.cardFees = action.payload.data;
      return newState;
    }

    default:
      return state;
  }
}

export default GiftReducer;