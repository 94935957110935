import { notificationShow, allNotificationsHide } from '@frontend/common';

/*
*
* Middleware
*
*/
export const apiMessages = store => next => action => { // eslint-disable-line
  const { payload } = action;

  if (payload) {
    const errorMessages = [];
    const giactMessages = [];
    const infoMessages = [];
    const warningMessages = [];
    const lockedOutMessages = [];

    // Handler for API error codes
    if (payload.status >= 299 && payload.status < 400) { // Redirection
      errorMessages.push('A temporary redirect was issued by the server.');
      window.location.href = '/';
    }
    else if (payload.status === 400) { // payload.data may be an array of messages.
      if (payload.data && payload.data.length > 0) {
        payload.data.forEach(msg => {
          switch (msg.MessageType) {
            case 0: infoMessages.push(msg.Message); break;
            case 1: warningMessages.push(msg.Message); break;
            case 2: {
              if (msg.Field === 'GiactFailed') { // catching only GIACT field
                giactMessages.push(msg.Message);
                break;
              }
              else { // send everything else to a toast error
                errorMessages.push(msg.Message);
                break;
              }
            } 
            case 3: lockedOutMessages.push(msg.Message); break; // always show a toast message no inline errors
            default: break;
          }
        });
      }
      else {
        errorMessages.push('A bad request. No messages were provided. Status: 400.');
      }
    }
    else if (payload.status === 404) {
      errorMessages.push('Not found. Status: 404.');
    }
    else if (payload.status > 404 && payload.status < 409) { // race condition
      errorMessages.push('You are attempting an action that is not allowed. Contact my529 if you continue to experience this issue.');
    }
    else if (payload.status === 409) {
      errorMessages.push('You are attempting to modify a record that is in the process of being modified.');
    }
    else if (payload.status > 400 && payload.status < 600) {
      errorMessages.push('There was an issue communicating with the server. Please try again later, or contact my529 if you continue to experience this issue.');
    }

    if (errorMessages.length > 0) {
      errorMessages.forEach(error => store.dispatch(notificationShow(error, 'error')));
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      return Promise.reject(action);
    }

    // catch only GIACT errors
    if (giactMessages.length > 0) {
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      return Promise.reject(action);
    }

    if (warningMessages.length > 0) {
      warningMessages.forEach(warning => store.dispatch(notificationShow(warning, 'warning')));
    }

    if (infoMessages.length > 0) {
      infoMessages.forEach(info => store.dispatch(notificationShow(info, 'warning')));
    }

    if (lockedOutMessages.length > 0) {
      // clear all other toasts if any to show only one lockout message
      store.dispatch(allNotificationsHide());
      lockedOutMessages.forEach(error => store.dispatch(notificationShow(error, 'error')));
      const updatedAction = action;
      updatedAction.type = `${action.type}_LOCKOUT`;
      next(updatedAction);
      return Promise.reject(action);
    }

    return next(action);
  }

  // Non-async actions
  else {
    return next(action);
  }
};
