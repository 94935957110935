/*
 *
 * PrintMail Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Barcode from 'react-barcode';

import {
  Button,
} from '@mui/material';

import {
  currencyFormatter,
  sizify,
} from '@frontend/common';

import logoGifting from '../images/logo_gift.png';

import {
  LABEL_GO_BACK_TO_EDIT,
  LABEL_PRINT_FORM,
  SUPPORT_PHONE_NUMBER,
} from '../constants';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

const select = (state) => ({
  gift: state.giftReducer.gift,
  parameters: state.giftReducer.parameters,
});

export class PrintMail extends React.Component {
  static propTypes = {
    gift: PropTypes.object.isRequired,
    parameters: PropTypes.shape({
      Domain: PropTypes.string.isRequired,
    }),
    size: PropTypes.object.isRequired,
  };

  render() {
    const { gift, parameters, size, } = this.props;
    const backToEditURL = gift.GiftAmounts ? `/${gift.GiftAmounts[0].Code}` : '/';

    return (
      <div className={styles.container}>
        <div className={`${parentStyles.buttons} hideOnPrint`}>
          <div className={parentStyles.button}>
            <Button
              key='print'
              variant='outlined'
              onClick={() => this.props.history.push(backToEditURL)}
              color='primary'
            >
              {LABEL_GO_BACK_TO_EDIT}
            </Button>
          </div>
          <div className={parentStyles.button}>
            <Button
              key='print'
              variant='contained'
              onClick={() => window.print()}
            >
              {LABEL_PRINT_FORM}
            </Button>
          </div>
        </div>
        <div className={styles.cutWindow}>
          {gift.GiftAmounts && gift.GiftAmounts.map((giftAmount, index) => {
            // insert a page break every 2nd checkslip
            const insertNow = (index + 1) % 2 === 0;
            return (
              <div className={`${styles.checkSlip} ${insertNow && 'printPageBreakAfter'}`} key={`check_${index}`}>
                <div className={styles.leftColumn}>
                  <img src={logoGifting} className={styles.logo} alt='gifting logo' />
                  <div>Make checks payable to <b><i>my529</i></b></div>
                  <div className={styles.barcode}><Barcode value={giftAmount.Code} width={size.windowWidth > 480 ? 3 : 2} /></div>
                </div>
                <div className={styles.rightColumn}>
                  <div className={styles.names}>
                    <p>Beneficiary: {giftAmount.BeneficiaryName}</p>
                    <p>Account Owner: {giftAmount.AgentName}</p>
                    <p>Gift Giver: {gift.FirstName} {gift.LastName}</p>
                  </div>
                  <div className={styles.amount}>
                    <p><b>Amount enclosed:</b></p>
                    <p>{currencyFormatter(giftAmount.Amount)}</p>
                    <hr />
                  </div>
                  <div className={styles.checkAddress}>
                    <p><b>my529</b></p>
                    <p>PO Box 145100</p>
                    <p>Salt Lake City, UT 84114-5100</p>
                  </div>
                </div>
              </div>
            );
          }
          )}

          <hr className={styles.dashedDivider} />
          <ol>
            <li>Print this form.</li>
            <li>Make your check payable to my529, and include beneficiary name(s) in the memo field.</li>
            <li>Mail the form and check to:
              <ul>
                <li>
                  Mail:<br />
                                my529<br />
                                PO Box 145100<br />
Salt Lake City, UT 84114-5100
                </li>
                <li>
                  Overnight delivery:<br />
                                    my529<br />
                                    60 South 400 West<br />
                                    Salt Lake City, UT 84101-1284
                </li>
              </ul>
            </li>
          </ol>
          <div>
            <h2>Important Legal Notice</h2>
            <p>my529 is a Section 529 plan administered and managed by the Utah State Board of Regents and the Utah Higher Education Assistance Authority (UHEAA).</p>

            <p>Your gift contribution will be attributed to you if you include your full name on the gift web page. This gift will be posted in the designated my529 account usually within three business days. Gifts are not revocable.</p>

            <p>my529 accounts are controlled by the account owner, who may change the beneficiary, withdraw or transfer funds, or change the account owner as set forth in the my529 Program Description. Only my529 account owners may control how assets are invested and possibly claim a Utah state income tax benefit. Under Utah tax law, the giver may not receive a Utah state income tax credit. Your donation may have gift tax consequences. Please consult your tax advisor.</p>

            <p>Read the Program Description for more information and consider all investment objectives, risks, charges, and expenses before investing or receiving or making a gift contribution. Call {SUPPORT_PHONE_NUMBER} for a copy of the my529 Program Description or visit <a href={`https://${parameters.Domain}`} target='_blank' rel='noopener noreferrer'>{parameters.Domain}</a>.</p>

            <p>Investments are not guaranteed by my529, the Utah State Board of Regents, Utah Higher Education Assistance Authority (UHEAA), or any other state or federal agency. However, Federal Deposit Insurance Corporation (FDIC) insurance is provided for the FDIC-insured accounts. Please read the Program Description to learn about the FDIC-insured accounts. Your investment could lose value.</p>

            <p>Non-Utah taxpayers and residents: You should determine whether the state in which you or your beneficiary pays taxes or lives offers a 529 plan that provides state tax or other benefits not otherwise available to you by investing in my529. You should consider such state tax treatment and benefits, if any, before investing in my529.</p>
          </div>

        </div>

      </div>

    );
  }
}

export default withRouter(sizify(connect(select, {})(PrintMail)));