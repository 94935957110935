import {
  GETrequest,
  POSTrequest,
} from 'utils/helpers/api_handler';

import {
  SAVE_GIFT_TO_REDUX,
  CLEAR_GIFT,
  RESET_GIFT_AMOUNTS,
  GET_GIFT_INFO,
  GET_USSTATES,
  GET_BANK_NAME,
  POST_GIFT,
  VALIDATE_GIFT_GIFTMAX,
  GET_PUBLIC_PARAMETERS,
  TOGGLE_GIFT_CODE_PAGE,
  GET_CARD_FEES,
  SEND_GIFT_NOTICE,
  GET_PDF_GIFT_NOTICE,
} from './constants';

export function getGiftInfoByCode(code) {
  const response = GETrequest(`/gift/${code}`);
  return {
    type: GET_GIFT_INFO,
    payload: response,
  };
}

export function getUSStates() {
  const response = GETrequest('/gift/states');
  return {
    type: GET_USSTATES,
    payload: response,
  };
}

export function getBankNameByRouting(routingNumber) {
  const response = GETrequest(`/gift/bank/${routingNumber}`);
  return {
    type: GET_BANK_NAME,
    payload: response,
  };
}

export function saveGiftToRedux(gift) {
  return {
    type: SAVE_GIFT_TO_REDUX,
    payload: gift,
  };
}

export function clearGift() {
  return {
    type: CLEAR_GIFT,
  };
}

export function resetGiftAmounts() {
  return {
    type: RESET_GIFT_AMOUNTS,
  };
}

export function postGift(gift) {
  const response = POSTrequest('/gift/save', gift);
  return {
    type: POST_GIFT,
    payload: response,
  };
}

export function validateGiftAndGetGiftMax(gift) {
  const response = POSTrequest('/gift/giftmax', gift);
  return {
    type: VALIDATE_GIFT_GIFTMAX,
    payload: response,
  };
}

export function getParameters() {
  const response = GETrequest('/gift/parameters');
  return {
    type: GET_PUBLIC_PARAMETERS,
    payload: response,
  };
}

export function toggleOnGiftCodePage() {
  return {
    type: TOGGLE_GIFT_CODE_PAGE,
  };
}

export function getCardFees() {
  const response = GETrequest('/gift/CreditCardFees');
  return {
    type: GET_CARD_FEES,
    payload: response,
  };
}

export function sendGiftNotice(giftInfo) {
  const response = POSTrequest('/Gift/notice', giftInfo);
  return {
    type: SEND_GIFT_NOTICE,
    payload: response,
  };
}

export function getPdfGiftNotice(giftInfo) {
  const payload = POSTrequest('/Gift/savenotice', giftInfo, { responseType: 'blob', timeout: 5000 }); // gift notice pdf is provided as a blob

  return {
    type: GET_PDF_GIFT_NOTICE,
    payload,
  };
}